/*
 * 업무구분: 변액 적합성
 * 화 면 명: MSPPI390M
 * 화면설명: 변액 적합성
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="변액적합성" ref="page" type="subpage" :show-title="true" @on-header-left-click="fn_HeaderBackBtnHandler">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      
      <ur-box-container alignV="start" direction="column" class="ns-radio-list2 mb30">
        <div class="list-item-wrapper">
          <div class="list-item pt29 pb28" :class="{'on':aprvType==='01'}" @click="aprvType='01',nextBtnDisabled=false">
            <div class="list-item__main">
              <mo-radio v-model="aprvType" value="01" @click="aprvType='01',nextBtnDisabled=false"></mo-radio>
              <div class="list-item__contents fs17rem" :class="{'crTy-blue3':aprvType==='01'}">
                <div class="list-item__contents__title mb0">
                  <span class="name txtSkip fwn">모바일 변액</span>
                </div>
              </div>
            </div>
          </div>
          <div class="list-item pt29 pb28" :class="{'on':aprvType==='02'}" @click="aprvType='02',nextBtnDisabled=false">
            <div class="list-item__main">
              <mo-radio v-model="aprvType" value="02" @click="aprvType='02',nextBtnDisabled=false"></mo-radio>
              <div class="list-item__contents fs17rem" :class="{'crTy-blue3':aprvType==='02'}">
                <div class="list-item__contents__title mb0">
                  <span class="name txtSkip fwn">서면 변액</span>
                </div>
                <div class="list-item__contents__info mt10">
                  <ul class="terms-list-area crTy-bk7 fs14rem">
                    <li>반드시 질문 수령 후 입력 바랍니다.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" direction="column" class="ns-btn-relative bottom60">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="nextBtnDisabled" @click.stop="fn_NextBtnClickHandler">다음</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPPI400P from '@/ui/pi/MSPPI400P' // 모바일 변액
import MSPPI450P from '@/ui/pi/MSPPI450P' // 서면 변액

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI390M',
  screenId: 'MSPPI390M',
  components: {
    'MSPPI400P': MSPPI400P, // 모바일 변액
    'MSPPI450P': MSPPI450P // 서면 변액
  },
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {},
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  // modalProps: {
  //   mode: 'right' // 우->좌 open popup (defalut: 하->상)
  // },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data() {
    return {
      aprvType: '',
      custInfo: {
        custNm: '', // 가입자 이름
        age: '', // 가입자 나이
        knb: '', // 가입자 주민 번호
        celno: '', // 가입자 전화번호
        emailAddr: '', // 가입자 이메일 주소
        chnlCustId: '' // 가입자 ID
      },
      nextBtnDisabled: true,
      pi400p: null,
      pi450p: null
    }
  },
  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  // router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  // beforeRouteEnter(to, from, next) { 반드시  next(fals) OR next()  호출 },
  // router에 의해 화면을 벗어날때 호출 
  // beforeRouteLeave(to, from, next) { 반드시  next(fals) OR next()  호출 },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // beforeCreate() {},
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
  },
  // beforeMount() {},
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  destroyed() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
  },
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_DeviceBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DeviceBackBtnHandler() {
      this.$router.push({name:'MSPBC002M'})
    },
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 상단 타이틀 백 아이콘 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$router.push({name:'MSPBC002M'})
    },
    /******************************************************************************
    * Function명  : fn_NextBtnClickHandler
    * 설명        : 다음 버튼 클릭 핸들러
    ******************************************************************************/
    fn_NextBtnClickHandler() {
      let lv_Vm = this
      if(this.aprvType === '01') {
        // 모바일 변액
        lv_Vm.pi400p = lv_Vm.$bottomModal.open(MSPPI400P, {
          properties: {
            custInfo: {},
            parentParam: {}
          },
          listeners: {
            onPopupClose: (pData) => {
              lv_Vm.$bottomModal.close(lv_Vm.pi400p)
              if(pData && pData.name !== '') {
                // 필수컨설팅동의 화면으로 이동 - MSPCM002M
                lv_Vm.$router.push(pData)
              }
            }
          }
        },
        {
          properties: {noHeader: true}
        })
      }else if(this.aprvType === '02') {
        // 서면 변액
        lv_Vm.pi450p = lv_Vm.$bottomModal.open(MSPPI450P, {
          properties: {
            pParams: null
          },
          listeners: {
            onConfirmed: () => {
              lv_Vm.$bottomModal.close(lv_Vm.pi450p)
            },
            cancelPopup: () => {
              lv_Vm.$bottomModal.close(lv_Vm.pi450p)
            },
            onClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.pi450p)
            }
          }
        },
        {
          properties: {noHeader: true}
        })
      }
    },

    //코딩 종료 함수 마지막 메서드 함수 , 없음 주의
    _fn_End() {return}
  }
}
</script>