/*
 * 업무구분: 모바일 변액
 * 화 면 명: MSPPI400M
 * 화면설명: 모바일 변액 적합성
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="모바일 변액" ref="page" :show-title="true" type="subpage" :topButton="true" @on-header-left-click="fn_ClosePopup">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <!-- <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview"> -->

          <!-- 헤더 타이틀 영역 -->
          <!-- <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <div slot="nav" class="icon-wrapper" @click="fn_ClosePopup()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '모바일 변액' : '모바일 변액'}}</div>
            </mo-top-bar>
          </template> -->

          <!-- collapsing 영역 -->
          <!-- <template #collapsing>
          </template> -->

          <!-- stickey 영역 -->
          <!-- <template #sticky>
          </template> -->

          <!-- scroll 영역 -->
          <!-- <template #scroll> -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box mb50"> 
                <div class="info-title-wrap mb0">
                  <span class="fwm fs18rem">고객명</span>
                </div> 
                <ur-box-container alignV="start" componentid="" direction="column" class="mt10">
                  <mo-text-field  class="form-input-name full" ref="refCustNmTextField" v-model="displayCustNm" :clearable="searchable && (displayCustNm !== '')" :searchable="searchable" underline placeholder="고객명을 입력해 주세요" @keyup.enter="fn_searchCustomerNm" @click-icon="fn_searchCustomerNm" :disabled="!searchable"/>
                </ur-box-container>
              </ur-box-container>
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box mb50"> 
                <div class="info-title-wrap mb0">
                  <span class="fwm fs18rem">휴대폰번호</span>
                </div> 
                <ur-box-container alignV="start" componentid="" direction="column" class="mt10">
                  <span class="fs17rem">{{displayCelno}}</span>
                </ur-box-container>
              </ur-box-container>
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box mb20"> 
                <div class="info-title-wrap mb0">
                  <span class="fwm fs18rem">이메일</span>
                </div> 
                <ur-box-container alignV="start" componentid="" direction="column" class="mt10">
                  <span class="fs17rem">{{displayEmailAddr}}</span>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container> 
            <ur-box-container direction="column" align-v="start" class="con-area pb50">            
              <div class="text-gray-box">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li>이메일 주소가 틀리거나 없는 경우 계약서류 및 작성된 전자문서를 이메일로 받으실 수 없습니다.</li>
                </ul>
              </div> 
            </ur-box-container>
          <!-- </template> -->
        <!-- </mo-collapsing-header-view> -->

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!is_CustomerSet" @click.stop="fn_SendMobile">알림톡 발송</mo-button>
          </div>
        </ur-box-container>
      <!-- </div> -->

      <!--START: confirm alert -->
      <mo-bottom-sheet ref="refConfirm" class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AlertClose('cancel')">{{alertBtnLb1}}</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AlertClose('ok')">{{alertBtnLb2}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: confirm alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refAlert" :close-btn="false" class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AlertClose()">{{alertBtnLb}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert -->

    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import DateUtil from '@/ui/ps/comm/PSDateUtil'
import ServiceManager from '@/ui/ps/comm/PSServiceManager'
import moment from 'moment'
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객조회
import MSPPI404P from '@/ui/pi/MSPPI404P' // 법인고객조회
import MSPCM300P from '@/ui/cm/MSPCM300P' // 고객카드수정

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPI400P',
  screenId: 'MSPPI400P',
  components: {
      'MSPCM129P': MSPCM129P, // 고객조회
      'MSPPI404P': MSPPI404P, // 법인고객조회
      'MSPCM300P': MSPCM300P // 고객카드수정
  },
  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props: {
    custInfo: {},
    parentParam: {}
  },
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data() {
    return {
      searchable: true, // 고객 검색 버튼 활성/비활성 화
      // lv_HeaderviewScrollCompID: null,
      displayCustNm: '', // 화면에 표시할 고객명
      displayCelno: '-', // 휴대폰번호
      displayEmailAddr: '-', // 이메일
      headerVO: {}, // 사용자 정보
      cnsltNo: '', // 사용자 ID
      checkedBpidFlag: false, // BP ID 체크 여부
      is_CustomerSet: false, // 고객 검색 후 이메일, 전화 번호 값이 설정 되었을 경우 true...
      isGENT: false, // 단체 가입으로 법인 대표명 검색 필요 시 true로...
      officeVal: false,
      // ASR210701055_적합성재진단 (20210923_PJO)
      suiTaChk: 'N', // 적합성 체크여부 (적합성이력 체크 후 재진단사유까지 입력받아야 Y)
      isTSSPI723P: false, // 재진단팝업
      rDignYn: '', // 재진단여부 : rDignYn / Y: 재진단대상
      rDignRsnCd: '', // 재진단사유코드 : rDignRsnCd / 01:사실관계 착오,02: 단순오기
      suita291VO: {}, // 적합성당일이력존재시 결과값
      isError: false, // 휴대폰,이메일 존재여부 / true  하나라도 미존재시 , fasle  둘다 존재시
      alertMsg: '',
      alertBtnLb: '확인',
      alertBtnLb1: '취소',
      alertBtnLb2: '',
      alertState: '', // alert 구분을 위한 상태값 저장 '1'-고객선택 전(초기화)화면 노출, '2'-고객선택 후 화면 노출
      confirmState: '',  // confirm 구분을 위한 상태값 저장 '1'-고객카드수정(MSPCM300P), '2'-고객등록동의(MSPCM002M)
      mspcm129P: {}, // MSPCM129P 고객조회 팝업
      msppi404p: {}, // MSPPI404P 법인고객조회 팝업
      mspcm300p: {} // MSPCM300P 고객카드 수정
    }
  },
  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것
  ***********************************************************************************/
  // router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨
  // beforeRouteEnter(to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter
  // router에 의해 화면을 벗어날때 호출 
  // beforeRouteLeave(to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // beforeCreate() {},
  created() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)

    this.headerVO = Object.assign({}, this.getStore('userInfo').getters.getUserInfo)
    this.cnsltNo = this.headerVO.userId // 사용자 ID를 변수를 설정 - 화면간 값 전달시 사용
    // GA 매니저 대행 컨설턴트 변경시
    if(this.$cmUtil.fn_GachangeTF()){
      this.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
    }
    this.fn_Init()
  },
  // beforeMount() {},
  mounted() {
    // 스크롤 애니메이션 instance 선언
    // this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    // document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  destroyed() {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DeviceBackBtnHandler)
  },
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},
  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},
  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_DeviceBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DeviceBackBtnHandler() {
      this.$emit('onPopupClose')
    },
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 상단 타이틀 백 아이콘 클릭 핸들러
    ******************************************************************************/
    fn_ClosePopup() {
      this.$emit('onPopupClose')
    },
    /******************************************************************************
     * 함수 : fn_Init
     * 설명 : 이전 화면에서 전달한 파라메터를 전달 받아 설정
    ******************************************************************************/
    fn_Init () {
      this.isGENT = this.parentParam.isGENT === undefined ? false : this.parentParam.isGENT // 단체 가입 시 법인 대표명으로 고객 검색 처리 flag ( 일단 임시코드로 사용 중 )
      this.custInfo.isGENT = this.isGENT
      this.officeVal = this.parentParam.isGENT === undefined ? false : this.parentParam.isGENT
      this.displayCustNm = this.custInfo.custNm === undefined ? '' : this.custInfo.custNm

      // 화면 호출 시 고객명 이 있으면, 고객 명으로 검색 수행
      if ( this.isGENT === false && ( ( String(this.displayCustNm).trim() !== '' ) && ( this.displayCustNm !== undefined )) ) {
        window.vue.getStore('progress').dispatch('UPDATE', true)
        this.searchable = false
        this.fn_reqCustInfoAftCorpRepr()
      }else {
        if(this.isGENT) {
          this.searchable = true
          this.displayCustNm = this.custInfo.custNm = ''
          this.alertState = '2'
          this.alertMsg = '계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.'
          setTimeout(() => {this.$refs.refAlert.open()}, 100)
        }else {
          // 전체메뉴에서 진입했고 고객명이 없을때 포커스 처리
          setTimeout(() => {this.$refs.refCustNmTextField.focus()},1000)
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_searchCustomerNm
    * 설명       : 돋보기 아이콘 선택 TSSPI722P 호출 - 고객 이름으로 조회 요청
    ******************************************************************************/
    fn_searchCustomerNm () {
      let lv_Vm = this
      
      if ( this.isGENT === true || this.officeVal === true) {
        // this.isTSSPI310P = true // 법인고객조회
        lv_Vm.msppi404p = lv_Vm.$bottomModal.open(MSPPI404P, {
          properties: {
            pCustNm: lv_Vm.displayCustNm // 고객명 파라미터
          },
          listeners: {
            confirmPopup: (pData) => {
              window.vue.getStore('progress').dispatch('UPDATE', true)
              lv_Vm.is_CustomerSet = false
              lv_Vm.$bottomModal.close(lv_Vm.msppi404p)
              if(!pData.emailAddr) {
                pData.emailAddr = ''
              }
              lv_Vm.fn_ConfirmFromPopup(pData)
            },
            cancelPopup: (pData) => {
              lv_Vm.$bottomModal.close(lv_Vm.msppi404p)
              if(pData && pData.action === 'goCM') {
                // 필수컨설팅동의 화면으로 이동
                let o = {}
                o.name = 'MSPCM002M'
                o.params = {}
                o.params.chnlCustId = pData.param.chnlCustId
                o.params.cnsltNo = lv_Vm.cnsltNo
                o.params.uuid = new Date().getTime()
                o.params.detailPopupOpen = true
                lv_Vm.$emit('onPopupClose', o)
              }
            }
          }
        },
        {
          properties: {noHeader: true}
        })
      } else {
        // this.isTSSPI722P = true // 개인고객조회
        lv_Vm.mspcm129p = lv_Vm.$bottomModal.open(MSPCM129P, {
          properties: {
            pCustNm: lv_Vm.displayCustNm // 고객명 파라미터
          },
          listeners: {
            onPopupConfirm: (pData) => {
              window.vue.getStore('progress').dispatch('UPDATE', true)
              lv_Vm.is_CustomerSet = false
              lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
              if(!pData.emailAddr) {
                pData.emailAddr = ''
              }
              lv_Vm.fn_ConfirmFromPopup(pData)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
            }
          }
        },
        {
          properties: {noHeader: true}
        })
      }
    },
    /******************************************************************************
    * Function명 : fn_ConfirmFromPopup
    * 설명       : 법인 팝업 검색 후 결과 처리 (전화 번호, Email 정보)
    *              fn_reqCustInfoAftCorpRepr()호출
    ******************************************************************************/
    fn_ConfirmFromPopup (lParam) {
      this.custInfo.custNm = this.displayCustNm = lParam.custNm.trim()
      this.custInfo.phoneNum = this.displayCelno = lParam.celno.trim() || '-'
      this.custInfo.emailAddr = this.displayEmailAddr = lParam.emailAddr.trim() || '-'
      this.custInfo.chnlCustId = lParam.chnlCustId.trim()
      this.custInfo.chnlCstgrId = lParam.chnlCstgrId.trim()
      this.custInfo.age = lParam.age

      // this.checkedBpidFlag = true

      // 개인 정보 검색 요청
      this.fn_reqCustInfoAftCorpRepr()
    },
    /******************************************************************************
    * Function명 : fn_CancelPopup
    * 설명       : 팝업 close
    ******************************************************************************/
    fn_CancelPopup (lParam) {
      this.isTSSPI310P = false
      this.isTSSPI722P = false
      this.isTSSPI723P = false // (20210923_PJO)

      if ( lParam !== undefined && lParam.action === 'goCM' ) {
        this.custInfo.custNm = this.displayCustNm = lParam.param.custNm.trim()
        this.custInfo.phoneNum = this.displayCelno = lParam.param.celno.trim() || '-'
        this.custInfo.emailAddr = this.displayEmailAddr = lParam.param.emailAddr.trim() || '-'
        this.custInfo.chnlCustId = lParam.param.chnlCustId.trim()
        this.custInfo.age = lParam.param.age
        this.$router.push({name: 'TSSCM207M', params: {chnlCustId: this.custInfo.chnlCustId, cnsltNo: this.cnsltNo, uuid: new Date().getTime(), detailPopupOpen: true}})
      }
    },
    /******************************************************************************
    * Function명 : fn_showConfirmMessage
    * 설명       : 휴대폰/ 이메일 정보가 없을 경우 화면에 표시할 문구 만들기
    ******************************************************************************/
    fn_showConfirmMessage () {
      this.alertBtnLb2 = '고객카드수정'
      this.isError = true
      if ( this.custInfo.celno.trim() === '' && (this.custInfo.emailAddr === '-' || this.custInfo.emailAddr.trim() === '') ) {
        this.alertMsg = '휴대폰/이메일 정보가 있어야 적합성 진단이 가능합니다.\n고객카드 수정화면으로 이동하시겠습니까?'
      } else if ( (this.custInfo.celno.trim() === '' || this.custInfo.celno === '-') && (this.custInfo.emailAddr !== '-' || this.custInfo.emailAddr.trim() !== '') ) {
        this.alertMsg = '휴대폰 정보가 있어야 적합성 진단이 가능합니다.\n고객카드 수정화면으로 이동하시겠습니까?'
      } else if ( (this.custInfo.celno.trim() !== '' || this.custInfo.celno !== '-') && (this.custInfo.emailAddr === '-' || this.custInfo.emailAddr.trim() === '') ) {
        this.alertMsg = '이메일 정보가 있어야 적합성 진단이 가능합니다.\n고객카드 수정화면으로 이동하시겠습니까?'
      } else {
        this.alertMsg = ''
        this.alertBtnLb2 = ''
        this.isError = false
        // 휴대폰,이메일 정상일경우 , 적합성이력 조회 (ASR210701055_20210923_PJO)
        this.fn_RequestSuitaQstnInve()
      }
      if(this.alertMsg !== '') {
        this.confirmState = '1'
        this.$refs.refConfirm.open()
        window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
      }
    },
    /******************************************************************************
    * Function명 : fn_AlertClose
    * 설명       : 법인 대표명 검색 후 고객 ID로 고객 정보( 전화번호, Email ) 요청
    ******************************************************************************/
    fn_AlertClose(val) {
      if(!val) {
        // alert
        this.$refs.refAlert.close()
        if(this.alertState === '1') {
          // 승인되지 않는 질문지가.... > 고객선택 전(초기화)화면 노출
          console.log('#### 승인되지 않는 질문지가.....')
        }else if(this.alertState === '2') {
          // 계약자가 법인인 경우 대표가.... > 고객선택 후 화면 노출
          console.log('#### 계약자가 법인인 경우 대표가.....')
          this.fn_searchCustomerNm()
        }else {

        }
        this.alertState = ''
      }else {
        // confirm
        this.$refs.refConfirm.close()
        if(val === 'ok') {
          if(this.confirmState === '1') {
            // MSPCM300P - 고객카드수정
            this.fn_openMSPCM300P()
          }else if(this.confirmState === '2') {
            // MSPCM002M - 고객등록동의
            let o = {}
            o.name = 'MSPCM002M'
            o.params = {}
            o.params.chnlCustId = this.custInfo.chnlCustId
            o.params.cnsltNo = this.cnsltNo
            o.params.uuid = new Date().getTime()
            o.params.detailPopupOpen = true
            this.$emit('onPopupClose', o)
          }
        }
        this.confirmState = ''
      }
    },
    /******************************************************************************
    * Function명 : fn_openMSPCM300P
    * 설명       : 고객카드 수정 팝업
    ******************************************************************************/
    fn_openMSPCM300P () {
      let lv_Vm = this
      lv_Vm.mspcm300p = lv_Vm.$bottomModal.open(MSPCM300P, {
        properties: {
          callerId: lv_Vm.$options.screenId,
          pChnlCustId: lv_Vm.custInfo.chnlCustId,
          pCnsltNo: lv_Vm.cnsltNo
        },
        listeners: {
          onPopupConfirm: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.mspcm300p)
            if(pData === 'U') {
              // 업데이트 -> 재조회
              lv_Vm.fn_searchCustomerNm()
            }
          },
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_Vm.mspcm300p)
          }
        }
      },
      {
        properties: {noHeader: true}
      })
    },
    /******************************************************************************
    * Function명 : fn_reqCustInfoAftCorpRepr
    * 설명       : 법인 대표명 검색 후 고객 ID로 고객 정보( 전화번호, Email ) 요청
    ******************************************************************************/
    fn_reqCustInfoAftCorpRepr () {
      var param = {
        cnsltNo: '', // this.cnsltNo, // 사용자 ID Set
        custId: this.cnsltNo,
        custNm: this.custInfo.custNm, // 고객 명 Set
        chnlCustId: this.custInfo.chnlCustId // 고객 ID Set
      }
      window.vue.getStore('progress').dispatch('UPDATE', true) // 시작
      piCommonUtil.invoke('UF1MDI0270', param, this.fn_reqCustInfoAftCorpReprHandler, null, this.fn_reqCustInfoAftCorpReprHandler, this, this)
    },
    /******************************************************************************
    * Function명 : fn_reqCustInfoAftCorpReprHandler
    * 설명       : 고객 고객 정보( 전화번호, Email ) 요청 후 처리
    ******************************************************************************/
    fn_reqCustInfoAftCorpReprHandler (returnData) {
      var lv_Vm = this
      // 임시 고객 처리
      if ( returnData.chnlCustScCd === '02' ) {
        lv_Vm.alertMsg = '임시고객입니다.\n가입설계 동의후 진행 가능합니다.'
        lv_Vm.$refs.refAlert.open()
        window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
        return
      }
      // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        lv_Vm.custInfo.custNm = returnData.custNm
        lv_Vm.custInfo.celno = returnData.celno
        lv_Vm.custInfo.emailAddr = returnData.emailAddr
        lv_Vm.custInfo.chnlCustId = returnData.chnlCustId
        lv_Vm.custInfo.resCutNm = returnData.custNm
        lv_Vm.custInfo.knb = returnData.knb
      } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
        console.log('고객 검색 Return값의 오류')
      }

      lv_Vm.displayCustNm = returnData.custNm.trim() // 고객명
      lv_Vm.displayCelno = returnData.celno.trim() || '-' // 휴대폰번호
      lv_Vm.displayEmailAddr = returnData.emailAddr.trim() || '-' // 이메일

      // BPID 조회
      lv_Vm.fn_ChkPartnerId(lv_Vm.displayCustNm, lv_Vm.custInfo.knb)

      // if (lv_Vm.checkedBpidFlag) {
      //   lv_Vm.checkedBpidFlag = false
      //   lv_Vm.fn_showConfirmMessage()
      // } else {
      //   lv_Vm.fn_ChkPartnerId(lv_Vm.displayCustNm, lv_Vm.custInfo.knb)
      // }
    },
    /*********************************************************
     * Function명: fn_ChkPartnerId
     * 설명: 선택한 고객정보로 BPID 유무 체크(DC)를 호출
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_ChkPartnerId (custName, custKnb) {
      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'DCDocuMngCtlImpl',
        reqMthdNm: 'chkPartnerId',
        targetSystemCode: 'sli.fom',
        reqVONm: 'sli.mo.vo.dc.eltrnsign.DCDocuMngVO',
        resVONm: 'sli.mo.vo.dc.eltrnsign.DCDocuMngVO'
      }
      let dcDocuMngVO = {
        rrno: custKnb, // 주민등록번호
        custNm: custName // 계약자명
      }
      window.vue.getStore('progress').dispatch('UPDATE', true) // progress
      piCommonUtil.invokeDirect(null, dcDocuMngVO, this.fn_ChkPartnerIdHandler, null, this.fn_ChkPartnerIdHandler, this, commHeader)
    },
    /******************************************************************************
    * Function명: fn_ChkPartnerIdHandler
    * 설명: BPID 체크 요청 이후 이벤트 핸들러
     * Params: N/A
     * Return: N/A
    ******************************************************************************/
    fn_ChkPartnerIdHandler (result) {
      let lv_Vm = this
      if ( result.rtnValue === '00' ) { // BPID 존재
        lv_Vm.fn_ChkMndtCnstgCnsnt() // 필컨여부 체크
      } else {
        // lv_Vm.alertMsg = 'BP ID가 생성되지 않은 고객입니다.\n필수컨설팅 동의 또는 고객등록 후\n다시 진행해주시기 바랍니다.'
        lv_Vm.alertMsg = '필수컨설팅동의를 받으신 고객만 적합성진단이 가능합니다.\n필수컨설팅동의로 이동하시겠습니까?'
        lv_Vm.alertBtnLb2 = '고객등록동의'
        lv_Vm.confirmState = '2'
        lv_Vm.$refs.refConfirm.open()
        window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
      }
    },
    /******************************************************************************
    * Function명: fn_ChkMndtCnstgCnsnt
    * 설명: 필컨여부 체크
    ******************************************************************************/
    fn_ChkMndtCnstgCnsnt() {
      let lv_Vm = this
      let pParams = {}
      pParams.chnlCustId = lv_Vm.custInfo.chnlCustId // 채널고객아이디
      pParams.cnsltNo = lv_Vm.cnsltNo // 컨설턴트번호
      pParams.whlInqrYn = 'Y' // 전체조회여부 - 디폴트
      pParams.conctScCd = '1' // 접속구분코드 5 (1.내부(INTRANET) 2.외부(INTERNET))
      // 동의정보조회
      this.post(lv_Vm, pParams, 'txTSSCM31SD', 'S')
      .then(function (response) {
        // 서버 데이터 가져오기
        if(response.body) {
          let tmpStr = response.body.mndtCnstgCnsntYn || 'N' // 필컨 여부
          if(tmpStr === 'Y') {
            lv_Vm.fn_showConfirmMessage()
          }else {
            lv_Vm.alertMsg = '필수컨설팅동의를 받으신 고객만 적합성진단이 가능합니다.\n필수컨설팅동의로 이동하시겠습니까?'
            lv_Vm.alertBtnLb2 = '고객등록동의'
            lv_Vm.confirmState = '2'
            lv_Vm.$refs.refConfirm.open()
            window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
          }
        }else {
          lv_Vm.alertMsg = '필수컨설팅동의를 받으신 고객만 적합성진단이 가능합니다.\n필수컨설팅동의로 이동하시겠습니까?'
          lv_Vm.alertBtnLb2 = '고객등록동의'
          lv_Vm.confirmState = '2'
          lv_Vm.$refs.refConfirm.open()
          window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
        }
      })
      .catch(function (error) {
        window.vue.error(error)
        window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
      })
    },
    /******************************************************************************
    * 함수 : fn_RequestSuitaQstnInve
    * 설명 : 가입 고객의 적합성 정보 조회 - 적합성 입력정보 조회(S1) - invoke call / (ASR210701055_20210923_PJO)
    ******************************************************************************/
    fn_RequestSuitaQstnInve () {
      var param = {
        cnsltNo: '',
        contrNm: this.custInfo.custNm,
        contrRrn: this.custInfo.knb
      }
      // ASR220800584_적합성재진단 사유코드 초기화(220906_PJO)
      console.log('>>UF1MDI0291조회전_rDignRsnCd:' + this.rDignRsnCd + ',rDignRsnCd:' + this.rDignRsnCd )
      this.rDignYn = ''
      this.rDignRsnCd = ''
      piCommonUtil.invoke('UF1MDI0291', param, this.fn_RequestSuitaQstnInveHandler, null, this.fn_RequestSuitaQstnInveHandler, this, this)
    },
    /********************************************************************************************
     * 함수 : fn_RequestSuitaQstnInveHandler
     * 설명 : 적합성 정보 조회(S1) 요청의 응답 처리/20210923_PJO
     ********************************************************************************************/
    fn_RequestSuitaQstnInveHandler (result) {
      if (result === null || result === '') { // 적합성이력 미존재
        this.suiTaChk = 'Y'
        this.is_CustomerSet = true
      } else {
        // 적합성이력 존재
        // 1. 미승인건 존재시 진행불가
        // 2. 당일 이력존재시 > 재진단 팝업체크 후 진행
        //    15~20번 항목은 기존 진단시 체크한 답변을 표시하되 당일 변경은 불가하도록 비활성화
        this.suita291VO = result
        console.log('>>UF1MDI0291결과값:inqrYn:' + result.inqrYn + '/ result.aprvYn:[' + result.aprvYn + '[/.rskEftAnlyYmd:[' + result.rskEftAnlyYmd + ']' + '[/.rskEftAnlyYmd:[' + String(result.rskEftAnlyYmd).trim() + ']')
        let rskEftAnlyYmd_Date = result.rskEftAnlyYmd === undefined || ( String(result.rskEftAnlyYmd).trim() !== '' ) ? result.rskEftAnlyYmd : ''
        if ( result.rskEftAnlyYmd !== undefined && String(result.rskEftAnlyYmd).trim() !== '') {
          let chkDay = DateUtil.addDate(rskEftAnlyYmd_Date, +91) // 91일 이내에 입력된 적합성 동의 내용이 승인됨
          console.log('>>91이후:' + chkDay + '/ fn_CurrentDate:' + DateUtil.fn_CurrentDate() )
          // 적합성 유효일자안에 미승인건 존재시 진행불가
          if ( chkDay > DateUtil.fn_CurrentDate() ) {
            if (result.inqrYn === 'Y') { // 조회여부 체크  // 확인필요, 왜 조회여부 체크하는지?
              if (result.aprvYn !== 'Y') { // 승인 여부 체크
                this.suiTaChk = 'N'
                this.is_CustomerSet = false
                this.alertMsg = '승인되지 않은 질문지가 있습니다.\n질문지 승인 후 재입력 가능합니다.'
                this.alertState = '1'
                this.$refs.refAlert.open()
                window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
                return
              } else {
                this.is_CustomerSet = true
              }
            } else {
              this.is_CustomerSet = true
            }
          }else {
            this.is_CustomerSet = true
          }
        } else {
          console.log('>>재진단대상아님_이력없습')
          this.rDignYn = 'N' // 재진단여부
          this.suiTaChk = 'Y'
          this.is_CustomerSet = true
        }
      }
      window.vue.getStore('progress').dispatch('UPDATE', false) // 종료
      console.log('>>UF1MDI0291조회후_rDignRsnCd:' + this.rDignRsnCd + ',rDignRsnCd:' + this.rDignRsnCd)
    },
    /********************************************************************************************
    * 함수 : fn_Reset
    * 설명 : 화면 초기화
    ********************************************************************************************/
    fn_Reset() {
      this.displayCustNm = '' // 화면에 표시할 고객명
      this.displayCelno = '-' // 휴대폰번호
      this.displayEmailAddr = '-' // 이메일
      this.is_CustomerSet = false
    },
    /********************************************************************************************
     * 함수 : fn_SendMobile
     * 설명 : 알림톡 발송 요청
     ********************************************************************************************/
    fn_SendMobile() {
      // 고객 BP ID 조회
      let pParams = {}
      pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS13S6'))
      pParams.data.BUSINESSPARTNER.GENERALDATA_S = {}
      pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_PERSON = {}
      pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_PERSON.LASTNAME = this.custInfo.custNm
      pParams.data.BUSINESSPARTNER.IDNUMBERS_T = [{IDENTIFICATIONNUMBER: this.custInfo.knb}]
      pParams.data.IS_HEADER.USERID = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.data.IS_HEADER.USERID = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      this.searchResult = []
      this.searchResult2 = []
      this.mainCustId = ''
      window.vue.getStore('progress').dispatch('UPDATE', true)
      ServiceManager.invoke(pParams, this.getCustIdResultHandler, null, this.getCustIdFaultHandler)
    },
    getCustIdResultHandler (event) {
      let resData = event.data
      this.custInfo.bpId = resData.businesspartner.partner
      if (piCommonUtil.isEmpty2(this.custInfo.bpId)) {
        console.log('txTSSPS13S6 LocalCallBack : BP ID 조회 실패')
        piCommonUtil.getFDPConfirm('s', 'BP ID 조회 실패')
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      }
      // 모바일청약 발송
      this.fn_mobpsPblProc()
    },
    getCustIdFaultHandler () {
      console.log('txTSSPS13S6 LocalCallBack : 주피 BP ID 조회 중 에러 발생')
      piCommonUtil.getFDPConfirm('s', '주피 BP ID 조회 중 에러 발생')
    },    
    /******************************************************************************
    * Function명 : fn_mobpsPblProc
    * 설명       : 모바일청약 발행 처리
    ******************************************************************************/
    async fn_mobpsPblProc () {
      let docId = moment(new Date().toISOString()).format('YYYYMMDDHHmmssSSS')
      let lv_Vm = this
      let param = {}
      const trnstId = 'txTSSPS80P1'
      const auth = 'I'
      param = {}
      param.isSendKakao = 'Y'
      param.vuchId = docId // 영수증ID
      param.elstPlanId = docId // 전자서명설계ID
      param.clctCnsltNo = this.cnsltNo // 모집컨설턴트번호
      param.insrOfrYmd = DateUtil.fn_CurrentDate() // 보험청약일자
      param.ernam = this.cnsltNo // 생성자id
      param.aenam = this.cnsltNo // 변경자id
      let mobpsRegContPtcpList = []
      let mobpsRegContPtcp = {}
      mobpsRegContPtcp.mobslElstScCd = 'H' // 변액(H), 외화(I)
      mobpsRegContPtcp.contPtcpScCd = '1' // 계약관계인구분코드
      mobpsRegContPtcp.elstPartnNo = this.custInfo.bpId // 전자서명파트너번호
      mobpsRegContPtcp.mobslChnlCustId = this.custInfo.chnlCustId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.custInfo.chnlCstgrId // 모바일영업채널고객그룹ID
      mobpsRegContPtcp.mobslChnlCustNm = this.custInfo.custNm // 모바일영업채널고객명
      mobpsRegContPtcp.MobpsContrRltnScCd = '01' // 모바일청약계약자관계구분코드, 01:본인
      mobpsRegContPtcp.mobpsSignMthCd = 'K' // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = 1 // 카카오톡알림발송순번
      mobpsRegContPtcpList.push(mobpsRegContPtcp)
      // 계약관계자 목록 저장
      param.mobpsRegContPtcpList = mobpsRegContPtcpList


      this.post(lv_Vm, param, trnstId, auth)
        .then(function (response) {
          if (response.body != null) {
            lv_Vm.alertMsg = '알림톡 발송이 완료되었습니다.'
            if(lv_Vm.searchable) {
              lv_Vm.fn_Reset()
            }
          } else {
            // 발행중 오류 발생시 메시지 표시
            lv_Vm.alertMsg = response.msgComm.msgDesc
          }
          lv_Vm.$refs.refAlert.open()
          window.vue.getStore('progress').dispatch('UPDATE', false)
        }).catch(function (error) {
          window.vue.error(error)
          window.vue.getStore('progress').dispatch('UPDATE', false)
        })
    },

    _fn_End(){return} // 코딩 종료 함수
  }
}
</script>