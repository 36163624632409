/*
 * 업무구분: 서면 변액
 * 화 면 명: MSPPI450M
 * 화면설명: 서면 변액 적합성
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="서면 변액" ref="page" :show-title="false" type="subpage" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_ClosePopup()"></mo-button>
              <div slot="nav" class="icon-wrapper" @click="fn_ClosePopup()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '서면 변액' : '서면 변액'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area mt20">
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
                <ur-box-container alignV="start" componentid="" direction="column" class="row-box align-item-center bd-b-Ty2 pt16 pb11">
                  <span class="w100px h100 mr20 fs16rem"><span>고객명</span></span>
                  <div @click.stop="fn_searchCustomerNm">
                  <!-- <mo-text-field class="form-input-name flex-1 mb0" ref="refCustNmTextField" v-model="displayCustNm" :clearable="searchable && (displayCustNm !== '')" :searchable="searchable" underline placeholder="고객명을 입력해 주세요" @keyup.enter="fn_searchCustomerNm" @click-icon="fn_searchCustomerNm" :disabled="isDisableCust"/> -->
                    <mo-text-field class="form-input-name flex-1 mb0" ref="refCustNmTextField" v-model="displayCustNm" :searchable="searchable" underline placeholder="고객명을 입력해 주세요" disabled/>
                  </div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="row-box align-item-center bd-b-Ty2 pt16 pb11">
                  <span class="w100px h100 fs16rem"><span>주민등록번호</span></span>
                  <span class="flex-1 fs16rem fwb ml20">{{custInfo.lbContrRrn}}</span>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>   

            <ur-box-container alignV="start" componentid="" direction="column" class="question-area">
              <div class="question_list"> 
                <!-- question result -->
                <div class="list-item" :class="{'on':card0.detailAreaOpen}" @click="card0.detailAreaOpen=!card0.detailAreaOpen" v-if="card0.result"><!-- // 포커싱: on -->         
                  <div class="list-item__contents">
                    <ur-box-container alignV="start" componentid="" direction="column" class="info-box" >
                      <div class="q-title">적합성 진단결과</div>
                      <div class="q-content full mt10">
                        <ur-box-container alignV="start" componentid="" direction="column" class="row-box fexTy3 bd-T-Ty1 bd-b-Ty2 pt16 pb12">
                          <span class="">보험상품 적합성</span>
                          <span class="w120px fex-clumn txt-right">
                            <b class="crTy-orange2">{{lbInsrAnlyRslt}}</b>
                            <span class="fs14rem crTy-bk7">{{lbInsrAnlyRslt_Date}}</span> 
                          </span>               
                        </ur-box-container>
                        <ur-box-container alignV="start" componentid="" direction="column" class="row-box fexTy3 bd-b-Ty2 pt16 pb12">
                          <span class="">특별계정펀드 적합성</span>
                          <span class="w160px fex-clumn txt-right">
                            <b class="crTy-purple1">{{lbRskEftAnlyRslt}}</b>
                            <span class="fs14rem crTy-bk7">{{lbRskEftAnlyRslt_Date}}</span> 
                          </span>
                        </ur-box-container>
                        <ur-box-container alignV="start" componentid="" direction="column" class="row-box fexTy3 bd-b-Ty2 pt16 pb12">
                          <span class="">승인상태</span>
                          <span class="w120px fex-clumn txt-right">
                            <b class="crTy-orange2">{{aprvMsg}}</b>
                          </span>
                        </ur-box-container>
                      </div>  
                    </ur-box-container>
                  </div>
                </div>
                <!-- // question result -->

                <!-- 전문금융소비자여부 -->
                <div class="list-item" :class="{'on':card00.detailAreaOpen,'selected':card00.selected}" @click.stop="card00.detailAreaOpen=!card00.detailAreaOpen"><!-- // 포커싱: on, 문항 답변시 selected class 추가 -->
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num mr4">0.</b><span class="">전문금융소비자여부</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">전문금융소비자여부</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item">
                          <mo-radio class="radio" v-model="c00" value="1" @input="fn_SetDataQ01(1)" :disabled="isDisableIns">일반금융소비자</mo-radio>
                        </div>
                        <div class="q-item">
                          <mo-radio class="radio" v-model="c00" value="2" @input="fn_SetDataQ01(2)" :disabled="isDisableIns">전문금융소비자</mo-radio>
                          <mo-button class="ns-btn-round white sm" :disabled='isDisableBtnExprt' @click.stop="fn_showTSSPI721P()">필수확인사항</mo-button>
                        </div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card00.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 금융취약계층 -->
                <div class="list-item" :class="{'on':card000.detailAreaOpen,'selected':card000.selected}" @click="fn_SetDataQ02Control()">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num mr4">0.</b><span class="">금융취약계층</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">금융취약계층</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="1" @input="fn_SetDataQ02(1)" :disabled="isDisableIns">해당없음</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="2" @input="fn_SetDataQ02(2)" :disabled="isDisableIns">해당(65세 이상)</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="3" @input="fn_SetDataQ02(3)" :disabled="isDisableIns">해당(미성년자)</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="4" @input="fn_SetDataQ02(4)" :disabled="isDisableIns">해당(정신적 장애 등)</mo-checkbox></div>
                      </ur-box-container>
                      <div class="text-blue-box mt20" v-if="!isDisableLeglAgnt">
                        <div class="fwm">미성년 정신적 장애 등의 경우, 법정대리인이 대신 적합성 진단을 하였습니까?</div>
                        <div class="ns-radio-list flex mt16">
                          <mo-radio class="bgwhite" v-model="c000" value="1" @input="fn_SetDataQ03(1)">예</mo-radio>
                          <mo-radio class="bgwhite" v-model="c000" value="2" @input="fn_SetDataQ03(2)">아니오</mo-radio>
                        </div>
                      </div>
                      <div class="mt20">
                        <ul class="terms-list-area crTy-bk7 fs14rem">
                          <li>취약금융소비자란 고령 투자자(만 65세 이상), 미성년자, 정신적 장애로 일상이나 사회생활에 제약을 받는 자 등으로 합리적 판단이 곤란한 자로서 계약자 보호의 필요성이 높은 자를 의미합니다.</li>
                        </ul>
                      </div>
                      <div class="ns-btn-relative-s">
                        <div class="relative-div mt20">
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="subBtn000" @click.stop="fn_SetDataQ02Confirm()">확인</mo-button>
                        </div>
                      </div>
                    </div>
                    <div class="q-result">
                      <div v-if="card000.selectedValue1 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card000.selectedValue1}}</span></div>
                      <div v-if="card000.selectedValue2 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card000.selectedValue2}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 1. 가입목적 금융상품 -->
                <div class="list-item" :class="{'on':card1.detailAreaOpen,'selected':card1.selected}" @click="card1.detailAreaOpen=!card1.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">1.</b><span class="ml4">가입목적 금융상품</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">가입목적 금융상품</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="1" @input="fn_SetDataAssort(1,1)" :disabled="isDisableIns">예금·적금</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="2" @input="fn_SetDataAssort(1,2)" :disabled="isDisableIns">주식·채권 등 금융투자상품</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="3" @input="fn_SetDataAssort(1,3)" :disabled="isDisableIns">일반보험</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="4" @input="fn_SetDataAssort(1,4)" :disabled="isDisableIns">변액보험</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card1.selectedValue}}</span></div>
                    </div>
                  </div> 
                </div>
                <!-- 2. 변액보험 가입목적 -->
                <div class="list-item" :class="{'on':card2.detailAreaOpen,'selected':card2.selected}" @click="card2.detailAreaOpen=!card2.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">2.</b><span class="ml4">변액보험 가입목적</span></div>
                      <div class="q-selected">
                        <mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">변액보험 가입목적</span>
                      </div>
                    </div>
                    <div class="q-content">       
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="1" @input="fn_SetDataAssort(2,1)" :disabled="isDisableIns">단기 재산 증식</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="2" @input="fn_SetDataAssort(2,2)" :disabled="isDisableIns">장기 저축을 통한 목돈 마련</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="3" @input="fn_SetDataAssort(2,3)" :disabled="isDisableIns">노후를 위한 연금자산 마련</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="4" @input="fn_SetDataAssort(2,4)" :disabled="isDisableIns">위험에 대비한 가족의 보장자산 마련</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card2.selectedValue}}</span></div>
                    </div>
                  </div> 
                </div>
                <!-- 3. 월 평균 소득 -->
                <div class="list-item" :class="{'on':card3.detailAreaOpen,'selected':card3.selected}" @click="card3.detailAreaOpen=!card3.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">3.</b><span class="ml4">월 평균 소득</span></div>
                      <div class="q-selected">
                        <mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">월 평균 소득</span>
                      </div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c3" value="1" @input="fn_SetDataAssort(3,1)" :disabled="isDisableIns">200만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c3" value="2" @input="fn_SetDataAssort(3,2)" :disabled="isDisableIns">500만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c3" value="3" @input="fn_SetDataAssort(3,3)" :disabled="isDisableIns">1,000만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c3" value="4" @input="fn_SetDataAssort(3,4)" :disabled="isDisableIns">1,000만원 초과</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 4. 월 평균 소득대비 보험료 비중 -->
                <div class="list-item" :class="{'on':card4.detailAreaOpen,'selected':card4.selected}" @click="card4.detailAreaOpen=!card4.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">4.</b><span class="ml4">월 평균 소득대비 보험료 비중</span></div>
                      <div class="q-selected">
                        <mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">월 평균 소득대비 보험료 비중</span>
                      </div>
                    </div>
                    <div class="q-content">                      
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="1" @input="fn_SetDataAssort(4,1)" :disabled="isDisableIns">10% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="2" @input="fn_SetDataAssort(4,2)" :disabled="isDisableIns">10~20%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="3" @input="fn_SetDataAssort(4,3)" :disabled="isDisableIns">20~30%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="4" @input="fn_SetDataAssort(4,4)" :disabled="isDisableIns">30~50%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="5" @input="fn_SetDataAssort(4,5)" :disabled="isDisableIns">50% 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card4.selectedValue}}</span></div>
                    </div>
                  </div> 
                </div>
                <!-- 5. 보험료 재원 순자산 -->
                <div class="list-item" :class="{'on':card5.detailAreaOpen,'selected':card5.selected}" @click="card5.detailAreaOpen=!card5.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">5.</b><span class="ml4">보험료 재원 순자산</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">보험료 재원 순자산</span></div>
                    </div>
                    <div class="q-content">                      
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="1" @input="fn_SetDataAssort(5,1)" :disabled="isDisableIns">1천만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="2" @input="fn_SetDataAssort(5,2)" :disabled="isDisableIns">5천만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="3" @input="fn_SetDataAssort(5,3)" :disabled="isDisableIns">1억원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="4" @input="fn_SetDataAssort(5,4)" :disabled="isDisableIns">1억원 초과</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card5.selectedValue}}</span></div>
                    </div>
                  </div> 
                </div>
                <!-- 6. 추가 납부 가능금액 -->
                <div class="list-item" :class="{'on':card6.detailAreaOpen,'selected':card6.selected}" @click="card6.detailAreaOpen=!card6.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">6.</b><span class="ml4">추가 납부 가능금액</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">추가 납부 가능금액</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="1" @input="fn_SetDataAssort(6,1)" :disabled="isDisableIns">10만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="2" @input="fn_SetDataAssort(6,2)" :disabled="isDisableIns">10~30만원</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="3" @input="fn_SetDataAssort(6,3)" :disabled="isDisableIns">30~50만원</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="4" @input="fn_SetDataAssort(6,4)" :disabled="isDisableIns">50~100만원</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="5" @input="fn_SetDataAssort(6,5)" :disabled="isDisableIns">100만원 초과</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card6.selectedValue}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 7. 보험료 납입가능기간 -->
                <div class="list-item" :class="{'on':card7.detailAreaOpen,'selected':card7.selected}" @click="card7.detailAreaOpen=!card7.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">7.</b><span class="ml4">보험료 납입가능기간</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">보험료 납입가능기간</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="1" @input="fn_SetDataAssort(7,1)" :disabled="isDisableIns">3년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="2" @input="fn_SetDataAssort(7,2)" :disabled="isDisableIns">7년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="3" @input="fn_SetDataAssort(7,3)" :disabled="isDisableIns">10년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="4" @input="fn_SetDataAssort(7,4)" :disabled="isDisableIns">20년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="5" @input="fn_SetDataAssort(7,5)" :disabled="isDisableIns">20년 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card7.selectedValue}}</span></div>
                    </div>
                  </div> 
                </div>
                <!-- 8. 예상 유지기간 -->
                <div class="list-item" :class="{'on':card8.detailAreaOpen,'selected':card8.selected}" @click="card8.detailAreaOpen=!card8.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">8.</b><span class="ml4">예상 유지기간</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">예상 유지기간</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="1" @input="fn_SetDataAssort(8,1)" :disabled="isDisableIns">7년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="2" @input="fn_SetDataAssort(8,2)" :disabled="isDisableIns">7년~10년</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="3" @input="fn_SetDataAssort(8,3)" :disabled="isDisableIns">10년~20년</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="4" @input="fn_SetDataAssort(8,4)" :disabled="isDisableIns">20년~30년</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="5" @input="fn_SetDataAssort(8,5)" :disabled="isDisableIns">30년 초과</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card8.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 9. 중도해지 가능성 -->
                <div class="list-item" :class="{'on':card9.detailAreaOpen,'selected':card9.selected}" @click="card9.detailAreaOpen=!card9.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">9.</b><span class="ml4">중도해지 가능성</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">중도해지 가능성</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="1" @input="fn_SetDataAssort(9,1)" :disabled="isDisableIns">30% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="2" @input="fn_SetDataAssort(9,2)" :disabled="isDisableIns">30~50%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="3" @input="fn_SetDataAssort(9,3)" :disabled="isDisableIns">50~70%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="4" @input="fn_SetDataAssort(9,4)" :disabled="isDisableIns">70~90%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="5" @input="fn_SetDataAssort(9,5)" :disabled="isDisableIns">90% 이상</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card9.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 10. 투자경험기간 -->
                <div class="list-item" :class="{'on':card10.detailAreaOpen,'selected':card10.selected}" @click="card10.detailAreaOpen=!card10.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">10.</b><span class="ml4">투자경험기간</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">투자경험기간</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c10" value="1" @input="fn_SetDataQ10(1)" :disabled="isDisableIns">투자경험 없음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c10" value="2" @input="fn_SetDataQ10(2)" :disabled="isDisableIns">6개월 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c10" value="3" @input="fn_SetDataQ10(3)" :disabled="isDisableIns">1년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c10" value="4" @input="fn_SetDataQ10(4)" :disabled="isDisableIns">2년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c10" value="5" @input="fn_SetDataQ10(5)" :disabled="isDisableIns">2년 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 11. 가입경험상품 -->
                <div class="list-item" :class="{'on':card11.detailAreaOpen,'selected':card11.selected}" @click="fn_SetDataQ11Control()">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">11.</b><span class="ml4">가입경험상품 <span class="fs-small">(최근 2년 이내)</span></span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">가입경험상품 <span class="fs-small">(최근 2년 이내)</span></span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice11" value="1" @input="fn_checkQ11(1)" :disabled="isDisableIns||isDisableInexpr">주식(관리, 위험종목) 등</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice11" value="2" @input="fn_checkQ11(2)" :disabled="isDisableIns||isDisableInexpr">주식(일반종목), 주식형 펀드(시장수익률) 등</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice11" value="3" @input="fn_checkQ11(3)" :disabled="isDisableIns||isDisableInexpr">혼합형 펀드 등</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice11" value="4" @input="fn_checkQ11(4)" :disabled="isDisableIns||isDisableInexpr">채권형 펀드 등</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice11" value="5" @input="fn_checkQ11(5)" :disabled="isDisableIns||isDisableInexpr">변액보험</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice11" value="6" @input="fn_SetDataQ11(6)" :disabled="isDisableIns||isDisableInexpr">투자경험 없음</mo-checkbox></div>
                      </ur-box-container>
                      <div class="ns-btn-relative-s">
                        <div class="relative-div mt20">
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="subBtn11" @click.stop="fn_SetDataQ11Confirm()">확인</mo-button>
                        </div>
                      </div>
                    </div>
                    <div class="q-result">
                      <div v-if="card11.selectedValue1 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue1}}</span></div>
                      <div v-if="card11.selectedValue2 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue2}}</span></div>
                      <div v-if="card11.selectedValue3 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue3}}</span></div>
                      <div v-if="card11.selectedValue4 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue4}}</span></div>
                      <div v-if="card11.selectedValue5 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue5}}</span></div>
                      <div v-if="card11.selectedValue6 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue6}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 12. 투자 경험 목적 -->
                <div class="list-item" :class="{'on':card12.detailAreaOpen,'selected':card12.selected}" @click="fn_SetDataQ12Control()">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">12.</b><span class="ml4">투자 경험 목적</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">투자 경험 목적</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice12" value="1" @input="fn_SetDataQ12(1)" :disabled="isDisableIns||isDisableInexpr">투자경험 없음</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice12" value="2" @input="fn_checkQ12(2)" :disabled="isDisableIns||isDisableInexpr">단기재산증식</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice12" value="3" @input="fn_checkQ12(3)" :disabled="isDisableIns||isDisableInexpr">장기저축을 통한 목돈 마련</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice12" value="4" @input="fn_checkQ12(4)" :disabled="isDisableIns||isDisableInexpr">노후를 위한 연금자산 마련</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice12" value="5" @input="fn_checkQ12(5)" :disabled="isDisableIns||isDisableInexpr">위험에 대비한 가족의 보장자산 마련</mo-checkbox></div>
                      </ur-box-container>
                      <div class="ns-btn-relative-s">
                        <div class="relative-div mt20">
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="subBtn12" @click.stop="fn_SetDataQ12Confirm()">확인</mo-button>
                        </div>
                      </div>
                    </div>
                    <div class="q-result">
                      <div v-if="card12.selectedValue1 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card12.selectedValue1}}</span></div>
                      <div v-if="card12.selectedValue2 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card12.selectedValue2}}</span></div>
                      <div v-if="card12.selectedValue3 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card12.selectedValue3}}</span></div>
                      <div v-if="card12.selectedValue4 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card12.selectedValue4}}</span></div>
                      <div v-if="card12.selectedValue5 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card12.selectedValue5}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 13. 투자경험 금액규모 -->
                <div class="list-item" :class="{'on':card13.detailAreaOpen,'selected':card13.selected}" @click="card13.detailAreaOpen=!card13.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">13.</b><span class="ml4">투자경험 금액규모 <span class="fs-small">(최근 2년 이내)</span></span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">투자경험 금액규모 <span class="fs-small">(최근 2년 이내)</span></span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="1" @input="fn_SetDataQ13(1)" :disabled="isDisableIns||isDisableInexpr">투자경험 없음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="2" @input="fn_SetDataQ13(2)" :disabled="isDisableIns||isDisableInexpr">200만원 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="3" @input="fn_SetDataQ13(3)" :disabled="isDisableIns||isDisableInexpr">500만원 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="4" @input="fn_SetDataQ13(4)" :disabled="isDisableIns||isDisableInexpr">1,000만원 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="5" @input="fn_SetDataQ13(5)" :disabled="isDisableIns||isDisableInexpr">1,000만원 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card13.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 14. 투자경험 거래빈도 -->
                <div class="list-item" :class="{'on':card14.detailAreaOpen,'selected':card14.selected}" @click="card14.detailAreaOpen=!card14.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">14.</b><span class="ml4">투자경험 거래빈도 <span class="fs-small">(최근 2년 이내)</span></span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">투자경험 거래빈도 <span class="fs-small">(최근 2년 이내)</span></span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="1" @input="fn_SetDataQ14(1)" :disabled="isDisableIns||isDisableInexpr">투자경험 없음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="2" @input="fn_SetDataQ14(2)" :disabled="isDisableIns||isDisableInexpr">3회 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="3" @input="fn_SetDataQ14(3)" :disabled="isDisableIns||isDisableInexpr">3~5회</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="4" @input="fn_SetDataQ14(4)" :disabled="isDisableIns||isDisableInexpr">5~10회</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="5" @input="fn_SetDataQ14(5)" :disabled="isDisableIns||isDisableInexpr">10회 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card14.selectedValue}}</span></div> 
                    </div>
                  </div>
                </div>
                <!-- 15. 변액보험 등 금융상품 이해수준 -->
                <div class="list-item" :class="{'on':card15.detailAreaOpen,'selected':card15.selected}" @click="card15.detailAreaOpen=!card15.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">15.</b><span class="ml4">변액보험 등 금융상품 이해수준</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">변액보험 등 금융상품 이해수준</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c15" value="1" @input="fn_SetDataAssort(15,1)" :disabled="isDisableIns||isReSuita">이해 수준이 현저히 낮음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c15" value="2" @input="fn_SetDataAssort(15,2)" :disabled="isDisableIns||isReSuita">가입경험이 있는 상품의 구조와 위험에 대해 일정 부분 이해</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c15" value="3" @input="fn_SetDataAssort(15,3)" :disabled="isDisableIns||isReSuita">가입경험이 있는 상품의 구조와 위험에 대해 전체적으로 잘 이해</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card15.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 16. 위험성향 -->
                <div class="list-item" :class="{'on':card16.detailAreaOpen,'selected':card16.selected}" @click="card16.detailAreaOpen=!card16.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">16.</b><span class="ml4">위험성향</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">위험성향</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c16" value="1" @input="fn_SetDataAssort(16,1)" :disabled="isDisableIns||isReSuita">원금은 반드시 보존되어야 한다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c16" value="2" @input="fn_SetDataAssort(16,2)" :disabled="isDisableIns||isReSuita">원금 보존을 중요하게 생각한다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c16" value="3" @input="fn_SetDataAssort(16,3)" :disabled="isDisableIns||isReSuita">수익률이 어느 정도 되어야 한다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c16" value="4" @input="fn_SetDataAssort(16,4)" :disabled="isDisableIns||isReSuita">수익률을 중시한다</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card16.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 17. 기대수익률 -->
                <div class="list-item" :class="{'on':card17.detailAreaOpen,'selected':card17.selected}" @click="card17.detailAreaOpen=!card17.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">17.</b><span class="ml4">기대수익률</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">기대수익률</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c17" value="1" @input="fn_SetDataAssort(17,1)" :disabled="isDisableIns||isReSuita">3% 이내</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c17" value="2" @input="fn_SetDataAssort(17,2)" :disabled="isDisableIns||isReSuita">7% 이내</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c17" value="3" @input="fn_SetDataAssort(17,3)" :disabled="isDisableIns||isReSuita">10% 이내</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c17" value="4" @input="fn_SetDataAssort(17,4)" :disabled="isDisableIns||isReSuita">15% 이내</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c17" value="5" @input="fn_SetDataAssort(17,5)" :disabled="isDisableIns||isReSuita">15% 초과</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card17.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 18. 손실감내수준 -->
                <div class="list-item" :class="{'on':card18.detailAreaOpen,'selected':card18.selected}" @click="card18.detailAreaOpen=!card18.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">18.</b><span class="ml4">손실감내수준</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">손실감내수준</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="1" @input="fn_SetDataAssort(18,1)" :disabled="isDisableIns||isReSuita">어떠한 경우에도 손실이 나면 안된다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="2" @input="fn_SetDataAssort(18,2)" :disabled="isDisableIns||isReSuita">원금 기준 10% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="3" @input="fn_SetDataAssort(18,3)" :disabled="isDisableIns||isReSuita">원금 기준 20% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="4" @input="fn_SetDataAssort(18,4)" :disabled="isDisableIns||isReSuita">원금 기준 30% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="5" @input="fn_SetDataAssort(18,5)" :disabled="isDisableIns||isReSuita">원금 기준 40% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="6" @input="fn_SetDataAssort(18,6)" :disabled="isDisableIns||isReSuita">원금 기준 50% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c18" value="7" @input="fn_SetDataAssort(18,7)" :disabled="isDisableIns||isReSuita">원금 기준 100% 이내는 감수할 수 있다</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card18.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 19. 주식형비중 -->
                <div class="list-item" :class="{'on':card19.detailAreaOpen,'selected':card19.selected}" @click="card19.detailAreaOpen=!card19.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">19.</b><span class="ml4">주식형비중</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">주식형비중</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c19" value="1" @input="fn_SetDataAssort(19,1)" :disabled="isDisableIns||isReSuita">5% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c19" value="2" @input="fn_SetDataAssort(19,2)" :disabled="isDisableIns||isReSuita">10% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c19" value="3" @input="fn_SetDataAssort(19,3)" :disabled="isDisableIns||isReSuita">20% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c19" value="4" @input="fn_SetDataAssort(19,4)" :disabled="isDisableIns||isReSuita">50% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c19" value="5" @input="fn_SetDataAssort(19,5)" :disabled="isDisableIns||isReSuita">50% 이상</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card19.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 20. 투자상품 보유비중 -->
                <div class="list-item" :class="{'on':card20.detailAreaOpen,'selected':card20.selected}" @click="card20.detailAreaOpen=!card20.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">20.</b><span class="ml4">투자상품 보유비중</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">투자상품 보유비중</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c20" value="1" @input="fn_SetDataAssort(20,1)" :disabled="isDisableIns||isReSuita">없음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c20" value="2" @input="fn_SetDataAssort(20,2)" :disabled="isDisableIns||isReSuita">30% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c20" value="3" @input="fn_SetDataAssort(20,3)" :disabled="isDisableIns||isReSuita">50% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c20" value="4" @input="fn_SetDataAssort(20,4)" :disabled="isDisableIns||isReSuita">50% 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card20.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>

              </div><!-- //question_list -->
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button v-if="isShowBtnCmplt" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isDisableBtnCmplt" @click.stop="fn_btnSuitaInptCmpltClickHandler">{{cmpltBtnTxt}}</mo-button>
            <mo-button v-if="isShowBtnOldDataUse" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_btnOldDataUseClickHandler">기존정보 사용</mo-button>
            <mo-button v-if="isShowBtnRetry" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isDisableBtnCmplt" @click.stop="fn_btnSuitaInptRetryClickHandler">선택완료</mo-button>
          </div>
        </ur-box-container>
      </div>

      <!--START: confirm alert -->
      <mo-bottom-sheet ref="refConfirm" class="ns-bottom-sheet" noHeader preventTouchClose>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AlertClose('cancel')">{{alertBtnLb1}}</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AlertClose('ok')">{{alertBtnLb2}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: confirm alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refAlert" :close-btn="false" class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AlertClose()">{{alertBtnLb}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refDefaultModal" :close-btn="false" class="ns-bottom-sheet" preventTouchClose>
        <template v-slot:title>적합성 원칙 필수 준수사항</template>
        <div class="customer-info-txt content-area">
          <p class="ment">
            ① 고객이 직접 작성하고 서명한 설문지가 있는 건에 限해 결과 입력<br>
                <!-- ※ 질문지 없이 입력할 경우 보험업法제95조의 3 [적합성 원칙]위반, <br> -->
            ※ 질문지 없이 입력할 경우 금융소비자보호법 제17조[적합성원칙] 위반<br><br>
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;자본시장법 제46조 [적합성의 원칙] 위반 <br><br> -->
            ② 설문 입력 후 질문지는 즉시 지점장 승인 요청 및 주임에게 제출<br>
            ※설문을 먼저 입력완료 후 승인 요청 하시기 바랍니다.<br><br>
            본 화면은 서면으로 고객의 서명을 받은 건을 입력하고 지점장에게 승인을 요청하는 화면입니다. 전자서명을 통한 적합성 진단은 &#0034;변액적합성(모바일)&#0034;을 이용하시기 바랍니다.
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_PrePopupBtnConfirmHandler()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refDignRsnAlert" :close-btn="false" class="ns-bottom-sheet" preventTouchClose>
      <template v-slot:title>재진단 확인</template>
      <div class="content-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" >
            <span class="fs17rem">고객님께서는 위험성향(적합성) 진단을 이미 하셨습니다. 위험성향(적합성)을 다시 진단 받고 싶으신 경우 재진단 사유를 선택해 주세요.</span>
            <span class="mt50 fs18rem">재진단 사유 선택</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-wrap mt10">
              <div class="ns-radio-list">
                <mo-radio v-model="rDignRsnCd" value="01" class="">사실관계 착오</mo-radio>
                <mo-radio v-model="rDignRsnCd" value="02" class="mt20">단순오기</mo-radio>
              </div>
            </ur-box-container>
          </ur-box-container> 
        </ur-box-container>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="medium" class="ns-btn-round white" @click="fn_CancelTSSPI723P()">취소</mo-button>
            <mo-button componentid="" color="primary" shape="primary" size="medium" class="ns-btn-round blue" @click="fn_ConfirmTSSPI723P()" :disabled="rDignRsnCd === ''">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!--END: alert -->

    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Gaf from '@/ui/pi/common/Gaf'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import DateUtil from '@/ui/ps/comm/PSDateUtil'
import GafMainFunc from '@/ui/pi/common/GafMainFunc'
import EventBus from '@/ui/pi/common//EventBus.js'
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객조회
import MSPPI452P from '@/ui/pi/MSPPI452P' // 전문금융소비자 입력용
import MSPPI404P from '@/ui/pi/MSPPI404P' // 법인고객조회

export default {
  name: 'MSPPI450P',
  screenId: 'MSPPI450P',
  isBackKeyHandle: true, // 팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션
  components: {
    'MSPCM129P': MSPCM129P, // 고객조회
    'MSPPI452P': MSPPI452P, // 전문금융소비자 입력용
    'MSPPI404P': MSPPI404P // 법인고객조회
  },
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClosePopup)

    this.isCustCheck = false
    this.isSuitaEditConfirm = false
    this.isChkErrorMsg = false
    this.isEditConfirm = false
    this.closeButtonEnabled = false // 닫기 버튼 막기
    this.headerVO = Object.assign({}, this.getStore('userInfo').getters.getUserInfo) // 사용자 정보 설정
    // GA 매니저 대행 컨설턴트 변경시
    if(this.$cmUtil.fn_GachangeTF()){
      this.headerVO.userId = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
    }

    console.log( 'this.pParams', this.pParams )
    if(this.pParams) {
      this.STR_suitaReportYN = this.pParams.suitaReportYN !== '' ? this.pParams.suitaReportPblYN : 'N'
      this.STR_suitaReportPblYN = this.pParams.suitaReportPblYN
    }

    if(!this.pParams) {
      // 전체메뉴에서 진입시
      Gaf.initStore('MSPPI450P')
      GafMainFunc.setStore('MSPPI450P')
      Gaf.clearPanelList()
      this.isDisableCust = false
      this.isShowBtnCmplt = true
      this.cmpltBtnTxt = '선택완료'
      this.btnStatus1 = '1'
    }
    Gaf.setProcessNo('1001')
    Gaf.addPanelList(this)

    window.vue.getStore('progress').dispatch('UPDATE', true)
    this.fn_init()
  },
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  mounted() {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy () {
    EventBus.$off('LocalCallBack')
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClosePopup)
  },
  destroyed() {
  },
  watch: {
    card0Selected () {
      console.log('##### card0Selected :'+this.card0Selected)
    },
    card00Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card000Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card1Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card2Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card3Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card4Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card5Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card6Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card7Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card8Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card9Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card10Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card11Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card12Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card13Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card14Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card15Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card16Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card17Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card18Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card19Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card20Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''}
  },
  methods: {
    fn_CheckAllItems() {
      if(this.aprvYn === 'Y') {
        return true
      }else {
        return (this.card00Selected && this.card000Selected && 
                this.card1Selected && this.card2Selected && this.card3Selected && this.card4Selected && this.card5Selected &&
                this.card6Selected && this.card7Selected && this.card8Selected && this.card9Selected && this.card10Selected &&
                this.card11Selected && this.card12Selected && this.card13Selected && this.card14Selected && this.card15Selected && 
                this.card16Selected && this.card17Selected && this.card18Selected && this.card19Selected && this.card20Selected)
      }
    },
    fn_CheckItems() {
      return (this.card00Selected || this.card000Selected || 
              this.card1Selected || this.card2Selected || this.card3Selected || this.card4Selected || this.card5Selected ||
              this.card6Selected || this.card7Selected || this.card8Selected || this.card9Selected || this.card10Selected ||
              this.card11Selected || this.card12Selected || this.card13Selected || this.card14Selected || this.card15Selected || 
              this.card16Selected || this.card17Selected || this.card18Selected || this.card19Selected || this.card20Selected)
    },
    /******************************************************************************
    * Function명 : fn_AlertClose
    * 설명       : 법인 대표명 검색 후 고객 ID로 고객 정보( 전화번호, Email ) 요청
    ******************************************************************************/
    fn_AlertClose(val) {
      if(val) {
        this.$refs.refConfirm.close()
        if(val === 'ok') {
          if(this.isRskEftAnlyRes) {
            this.fn_rskEftAnlyResPositive()
            return
          }
          if(this.isSuitaEditConfirm) {
            // 적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?
            this.fn_suitaEditConfirmPositive()
            return
          }
        }else if(val === 'cancel') {
          if(this.isRskEftAnlyRes) {
            this.fn_rskEftAnlyResNegative()
            return
          }
          if(this.isSuitaEditConfirm) {
            // 적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?
            this.fn_suitaEditConfirmNegative()
            return
          }
        }
      }else {
        this.$refs.refAlert.close()
        if(this.isRskEftAnlyAlert) {
          // 위험성향 진단 질문지 승인후 발행가능합니다.(진단일 + 3개월)
          this.isRskEftAnlyAlert = false
          return
        }
        if(this.isNotFndGstFlg) {
          this.isNotFndGstFlg = false
          return
        }
        if(this.isGENT && !this.isComplateGENSuita) {
          // 계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.
          // 법인고객조회 화면 띄움
          this.fn_onPositiveGENT()
          return
        }
        if(this.isPrpnsDignDnl) {
          // 적합성 이력이 있는 경우, 적합성 진단 거부 시 알림
          this.isPrpnsDignDnl = false
          return
        }
        if(this.isCustCheck) {
          // 고객 선택 후 진행하시기 바랍니다.
          this.isCustCheck = false
          return
        }
        if(this.isUnAprv) {
          // 승인되지 않은 질문지가 있습니다.\n질문지 승인 후 재입력 가능합니다.
          this.isUnAprv = false
          return
        }
        if(this.isSuitaExpire) {
          // 적합성진단 유효기간(91일)이 만료되어 사용하실 수 없습니다. 적합성진단을 재수행 바랍니다.', // 3개월->91일 (2021.11_PJO)
          this.isSuitaExpire = false
          return
        }
        if(this.isBmngrMsg) {
          // 지점장에게 승인요청 하시기 바랍니다.
          this.isBmngrMsg = false
          return
        }
        if(this.isEditConfirm) {
          // 영업관리자 ‘질문지 승인’ 이후에 상품설명서(제안용) 발행이 가능합니다
          this.isEditConfirm = false
          this.fn_EditConfirm()
          return
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_searchCustomerNm
    * 설명       : 돋보기 아이콘 선택 TSSPI722P 호출 - 고객 이름으로 조회 요청
    ******************************************************************************/
    fn_searchCustomerNm () {
      if(!this.searchable) return
      let lv_Vm = this
      if(lv_Vm.isGENT) {
        lv_Vm.fn_onPositiveGENT() // 법인 고객 조회
      }else {
        lv_Vm.mspcm129p = lv_Vm.$bottomModal.open(MSPCM129P, {
          properties: {
            pCustNm: lv_Vm.displayCustNm // 고객명 파라미터
          },
          listeners: {
            onPopupConfirm: (pData) => {
              window.vue.getStore('progress').dispatch('UPDATE', true)
              lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
              lv_Vm.displayCustNm = pData.custNm
              lv_Vm.chnlCustId = pData.chnlCustId
              lv_Vm.fn_CustomSearch()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
            }
          }
        },
        {
          properties: {noHeader: true}
        })
      }
    },
    /******************************************************************************
    * Function명 : fn_TSSPI190P_search
    * 설명       : 고객검색
   ******************************************************************************/
    fn_CustomSearch () {
      // 선택한 데이터 초기화
      this.fn_clearn()

      let zCustInfoCmpxVO = {
        // chnlClSalesScCd: '',
        custNm: this.displayCustNm,
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
      }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        zCustInfoCmpxVO.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      let inputJson = { zCustInfoCmpxVO: zCustInfoCmpxVO }
      if (this.displayCustNm !== '') {
        piCommonUtil.invoke('URLPQL00009', inputJson, this.URLPQL00009_LocalCallBack, null, this.URLPQL00009_LocalCallBack, this, this)
      } else {
        window.vue.getStore('progress').dispatch('UPDATE', false)
      }
    },
    /******************************************************************************
    * Function명 : URLPQL00009_LocalCallBack
    * 설명       : URLPQL00009의 localCallBack
   ******************************************************************************/
    URLPQL00009_LocalCallBack (result) {
      console.log('URLPQL00009_LocalCallBack :: ', result)
      var lv_Vm = this

      // 서비스 후처리
      if (result === null || result === undefined || result.body === null || !piCommonUtil.isObj(result.zcustInfoCmpxVO.custInfoVO)) {
        lv_Vm.isNotFndGstFlg = true
        lv_Vm.alertMsg = lv_Vm.isNotFndGstMsg
        lv_Vm.$refs.refAlert.open()
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      } else {
        let custInfoVO = null
        for(let i=0 ; i<result.zcustInfoCmpxVO.custInfoVO.length ; i++) {
          if(result.zcustInfoCmpxVO.custInfoVO[i].chnlCustId === lv_Vm.chnlCustId) {
            custInfoVO = result.zcustInfoCmpxVO.custInfoVO[i]
          }
        }
        if(custInfoVO === null && result.zcustInfoCmpxVO.custInfoVO.length > 0) {
          custInfoVO = result.zcustInfoCmpxVO.custInfoVO[0]
        }
        lv_Vm.custInfo.custNm = custInfoVO.custNm
        lv_Vm.custInfo.celno = custInfoVO.celno
        lv_Vm.custInfo.emailAddr = custInfoVO.emailAddr
        lv_Vm.custInfo.chnlCustId = custInfoVO.chnlCustId
        lv_Vm.custInfo.resCutNm = custInfoVO.custNm
        lv_Vm.custInfo.knb = custInfoVO.knb
        lv_Vm.custInfo.contrRrnFrno = custInfoVO.knb.substr(0, 6) // 주민번호 앞자리
        lv_Vm.custInfo.contrRrnBkno = custInfoVO.knb.substr(6) // 주민번호 뒷자리
      }
      lv_Vm.fn_setCustData()

      // 진입 후 알림 팝업 표시하면서 적합성 진단 수행
      lv_Vm.$refs.refDefaultModal.open()
      this.isDisableIns = false
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /****************************************************************************************
     * 함수 : fn_init
     * 설명 : 기본 정보, 화면 정보 초기화 처리
    ****************************************************************************************/
    fn_init () {
      let tmodel = Gaf.getObj('tmodel')

      if(!tmodel.rltnrInfo) {
        this.searchable = true
        this.card00.detailAreaOpen = true
        window.vue.getStore('progress').dispatch('UPDATE', false)
        // 전체메뉴에서 진입했고 고객명이 없을때 포커스 처리
        // setTimeout(() => {this.$refs.refCustNmTextField.focus()},1000)
        return
      }

      let rltnrInfo = ''
      // 적합성 진단자 - 계약자 기준으로 가져오기 로직수정 (ASR220700001 / 22.07.12_PJO)
      for ( let i = 0; i < tmodel.rltnrInfo.length; i++ ) {
        rltnrInfo = tmodel.rltnrInfo[i]
        if ( rltnrInfo.zzcontvInsrdCd === '11' ) {
          if ( rltnrInfo.zzcusDtlTypCd === '21') {
            // 단체 가입 처리 필요
            this.isGENT = true
          }
          this.rltnrInfo = rltnrInfo
          break
        }
      }

      if ( this.rltnrInfo === null || this.rltnrInfo === undefined || this.rltnrInfo === '' ) {
        this.isNotFndGstFlg = true // 고객 정보를 찿을 수 없습니다. alert
        this.alertMsg = this.isNotFndGstMsg
        setTimeout(() => {this.$refs.refAlert.open()},100)
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      } else {
        this.custInfo.chnlCustId = this.chnlCustId = this.rltnrInfo.zaAgtFileId
        this.custInfo.zzcusDtlTypCd = this.rltnrInfo.zzcusDtlTypCd
      }

      // 적합성 진단 대상이 단체 여부 처리
      if ( this.isGENT === true ) {
        let abdapolicyList = Gaf.getGafObjByRefId('POLICY')

        if ( abdapolicyList.length > 0 ) {
          let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
          let abdapolhldr = abdapolicy.abdapolhldr[0]
          let ztpqlCboextAddmAbdapolhldr = abdapolhldr.ztpqlCboextAddmAbdapolhldr

          if ( ztpqlCboextAddmAbdapolhldr.zaGNm !== '' && ztpqlCboextAddmAbdapolhldr.zaResntNm !== '' &&
          ztpqlCboextAddmAbdapolhldr.zaGNm !== undefined && ztpqlCboextAddmAbdapolhldr.zaResntNm !== undefined ) {
            // 단체명의 대표자가 있는경우
            this.searchable = false
            this.custInfo.custNm = this.displayCustNm = ztpqlCboextAddmAbdapolhldr.zaResntNm // 666
            this.custInfo.knb = ztpqlCboextAddmAbdapolhldr.zaResntRrnId // 계약자 주민번호
            this.custInfo.contrRrnFrno = ztpqlCboextAddmAbdapolhldr.zaResntRrnId.substr(0, 6) // 주민번호 앞자리
            this.custInfo.contrRrnBkno = ztpqlCboextAddmAbdapolhldr.zaResntRrnId.substr(6) // 주민번호 뒷자리
            this.custInfo.contrChnlCustId = ztpqlCboextAddmAbdapolhldr.zaRentAgtFileId // 계약자 채널고객ID
            this.custInfo.insrAge = GafMainFunc.calcAge(ztpqlCboextAddmAbdapolhldr.zaResntRrnId, piCommonUtil.getCurrentDate(), 'insurance')
            this.isComplateGENSuita = true
          } else {
            // 단체명의 대표자가 없는경우
            this.searchable = true
            this.custInfo.custNm = this.displayCustNm = ''
            this.custInfo.contrRrnFrno = ''
            this.custInfo.contrRrnBkno = ''
            this.custInfo.contrChnlCustId = ''
            this.custInfo.insrAge = 0
            this.isComplateGENSuita = false
            // 계약자가 법인인 경우 대표가 적합성 진단을 하셔야 합니다.
            this.alertMsg = '계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.'
            setTimeout(() => {this.$refs.refAlert.open()},100)
            window.vue.getStore('progress').dispatch('UPDATE', false)
            return
          }
        }
      } else { // 개인 계약자
        this.searchable = false
        this.custInfo.custNm = this.displayCustNm = rltnrInfo.zaInsrdNm // 계약자명
        this.custInfo.contrRrnFrno = rltnrInfo.zzcustRrnId.substr(0, 6) // 주민번호 앞자리
        this.custInfo.contrRrnBkno = rltnrInfo.zzcustRrnId.substr(6) // 주민번호 뒷자리
        this.custInfo.knb = rltnrInfo.zzcustRrnId
        this.custInfo.insrAge = rltnrInfo.ageatentryVl // 보험나이
      }
      this.fn_setCustData()
      this.fn_CustomSearch()
    },
    /******************************************************************************
    * Function명 : fn_onPositiveGENT
    * 설명       : 단체 가입으로 법인 대표명 검색이 필요할 경우 ComfirmPopup 표시
    ******************************************************************************/
    fn_onPositiveGENT () {
      this.isGENFlg = false
      this.isTSSPI310P = true

      let lv_Vm = this
      lv_Vm.msppi404p = lv_Vm.$bottomModal.open(MSPPI404P, {
        properties: {
          pCustNm: lv_Vm.displayCustNm || '' // 고객명 파라미터
        },
        listeners: {
          confirmPopup: (pData) => {
            window.vue.getStore('progress').dispatch('UPDATE', true)
            lv_Vm.$bottomModal.close(lv_Vm.msppi404p)
            lv_Vm.displayCustNm = pData.custNm
            lv_Vm.chnlCustId = pData.chnlCustId
            if(!pData.emailAddr) {
                pData.emailAddr = ''
              }
            lv_Vm.fn_ConfirmFromPopup(pData)
          },
          cancelPopup: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.msppi404p)
            if(pData && pData.action === 'goCM') {
              // 필수컨설팅동의 화면으로 이동
              let o = {}
              o.name = 'MSPCM002M'
              o.params = {}
              o.params.chnlCustId = pData.param.chnlCustId
              o.params.cnsltNo = lv_Vm.cnsltNo
              o.params.uuid = new Date().getTime()
              o.params.detailPopupOpen = true
              lv_Vm.$emit('cancelPopup', o)
            }
          }
        }
      },
      {
        properties: {noHeader: true}
      })
    },
    /******************************************************************************
    * Function명 : fn_ConfirmFromPopup
    * 설명       : 팝업 검색 후 다시 개인 정보(전화 번호, Email 정보) 검색 요청
    *              fn_reqCustInfoAftCorpRepr()호출
    ******************************************************************************/
    fn_ConfirmFromPopup (lParam) {
      this.lv_Items = lParam

      this.isTSSPI310P = false
      this.isTSSPI722P = false

      this.custInfo.custNm = this.displayCustNm = lParam.custNm
      this.custInfo.phoneNum = lParam.celno
      this.custInfo.emailAddr = lParam.emailAddr
      this.custInfo.chnlCustId = lParam.chnlCustId
      this.custInfo.insrAge = lParam.age
      this.custInfo.knb = lParam.knb // : "7107311******"

      if ( this.custInfo.knb !== undefined ) {
        this.custInfo.contrRrnFrno = this.custInfo.knb.substr(0, 6) // 주민번호 앞자리
        this.custInfo.contrRrnBkno = this.custInfo.knb.substr(6) // 주민번호 뒷자리
      }

      this.isComplateGENSuita = true
      this.fn_CustomSearch()
    },
    /****************************************************************************************
     * 함수 : fn_setCustData
     * 설명 : 데이터를 화면에 설정처리
    ****************************************************************************************/
    fn_setCustData () {
      if ( this.custInfo.contrRrnFrno !== '') {
        this.custInfo.lbContrRrn = this.custInfo.contrRrnFrno + '-' + this.custInfo.contrRrnBkno.substr(0, 1) + '******'
      } else {
        this.custInfo.lbContrRrn = '-'
      }

      this.isDisableBtnCmplt = true // 적합성선택완료
      // this.isDisableBtnDnl = true // 성향진단거부
      this.isDisableBtnExprt = true // 전문금융소비자
      this.isDisableBtnPlb = true // 진단결과 발행

      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /****************************************************************************************
     * 함수 : fn_ValidationSuitaData
     * 설명 : 분석결과전 Validation 체크
    ****************************************************************************************/
    fn_ValidationSuitaData () {
      // 선택 고객 없을시
      if ( this.custInfo.custNm === '' || this.custInfo.custNm === ' ' ) {
        this.isCustCheck = true
        this.alertMsg = this.ErrorMsg1
        this.$refs.refAlert.open()
        return false
      }

      // 미승인인 경우 알림 출력
      if (this.aprvYn === 'N') {
        this.isUnAprv = true
        this.alertMsg = this.ErrorMsg3
        this.$refs.refAlert.open()
        return false
      }

      // 적합성재진단 대상인데, 재진단사유 입력이 안된경우 재진단  팝업 오픈 (20220118_PJO)
      if ( this.isReSuita && this.rDignRsnCd === '' ) {
        // this.alertPopupSuitaFlag = true // alertPopupSuitaMsg = '재진단사유가 입력되지 않았습니다.\n\n재진단 사유 입력 하시겠습니까?'
        // this.isTSSPI723PConfirm = false
        // this.$refs.refDignRsnAlert.open()
        // return false
      }

      // 이전에 적합성 검사한 이력이 있는 경우
      // '적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?', 경고창 생성
      if ( this.strMB_prextSuitaYn === 'Y') {
        this.isSuitaEditConfirm = true
        this.alertMsg = this.ErrorMsg2
        this.alertBtnLb1 = '아니오'
        this.alertBtnLb2 = '예'
        this.$refs.refConfirm.open()
        return false
      }

      if (this.fn_checkInputAnswer()) {
        return false
      }
      this.isDisableBtnPlb = true // 위치이동 (20220118_PJO
      this.inputDone = true
      return true
    },
    /****************************************************************************************
    * 함수 : fn_btnSuitaInptCmpltClickHandler
    * 설명 : [적합성 선택완료] 버튼 클릭 시 - ( S2 ) 전 처리
    ****************************************************************************************/
    fn_btnSuitaInptCmpltClickHandler () {
      if(this.btnStatus1 === '1') {
        // 선택완료
        if ( this.fn_ValidationSuitaData() === false ) {
          return
        }
        window.vue.getStore('progress').dispatch('UPDATE', true)
        // 입력한 응답에 대한 데이터 처리 ( 요청도 해야되고 할게 많음 )
        this.fn_setSuitaAnswerData()
        // 결과 요청 인터페이스 호출
        this.fn_RequestSuitaDign()
      }else {
        // 적합성 발행
        this.fn_cbRskEftAnlyResConfirm()
      }
    },
    /****************************************************************************************
    * 함수 : fn_btnSuitaInptRetryClickHandler
    * 설명 : 재진단 선택완료 버튼 클릭 핸들러
    ****************************************************************************************/
    fn_btnSuitaInptRetryClickHandler () {
      // 선택완료
      if ( this.fn_ValidationSuitaData() === false ) {
        return
      }
      window.vue.getStore('progress').dispatch('UPDATE', true)
      // 입력한 응답에 대한 데이터 처리 ( 요청도 해야되고 할게 많음 )
      this.fn_setSuitaAnswerData()
      // 결과 요청 인터페이스 호출
      this.fn_RequestSuitaDign()
    },
    /****************************************************************************************
    * 함수 : fn_btnOldDataUseClickHandler
    * 설명 : 기존정보 사용 버튼 클릭 핸들러
    ****************************************************************************************/
    fn_btnOldDataUseClickHandler() {
      if(this.dcSuitaCnsntVO) {
        this.fn_clearn() // 화면 초기화
        this.oldDataUseFlag = true // 기존정보사용 플래그 셋팅
        this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼
        this.isDisableBtnCmplt = true // 선택완료, 진단결과 발행 버튼
        this.isShowBtnOldDataUse = this.isShowBtnRetry = false // 기존정보 사용 , 선택완료 버튼
        this.cmpltBtnTxt = '선택완료'
        this.btnStatus1 = '1' // 1-선택완료, 2-진단결과발행
        this.fn_RequestSuitaQstnInveHandler(this.dcSuitaCnsntVO) // 기존정보 화면 셋팅
      }else {
        console.log('######### 기존데이터 없음')
      }
    },
    /****************************************************************************************
     * 함수 : fn_checkInputAnswer
     * 설명 : [적합성 입력 값] 체크
    ****************************************************************************************/
    fn_checkInputAnswer () {
      this.ChkErrorMsg = ''
      // 입력값 체크
      if ( this.formInput.choice01.length < 1 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label01
      } else if ( this.formInput.choice01[0] === '2' && piCommonUtil.isEmpty(this.SuitaExportVO.exprtFncCspTypCd) ) { // 전문가금융소비자유형코드) {
        this.ChkErrorMsg = this.ErrorMsgs.label012
      } else if ( this.formInput.choice02.length === 0 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label02
      // } else if ( this.formInput.choice02[0] !== '1' && this.formInput.choice02[0] !== '2' && this.formInput.choice03.length === 0 ) {
      //   this.ChkErrorMsg = this.ErrorMsgs.label03
      } else if ( this.formInput.choice1.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label1
      } else if ( this.formInput.choice2.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label2
      } else if ( this.formInput.choice3.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label3
      } else if ( this.formInput.choice4.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label4
      } else if ( this.formInput.choice5.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label5
      } else if ( this.formInput.choice6.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label6
      } else if ( this.formInput.choice7.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label7
      } else if ( this.formInput.choice8.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label8
      } else if ( this.formInput.choice9.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label9
      } else if ( this.formInput.choice10.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label10
      } else if ( this.formInput.choice11.length === 0 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label11
      } else if ( this.formInput.choice12.length === 0 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label12
      } else if ( this.formInput.choice13.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label13
      } else if ( this.formInput.choice14.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label14
      } else if ( this.formInput.choice15.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label15
      } else if ( this.formInput.choice16.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label16
      } else if ( this.formInput.choice17.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label17
      } else if ( this.formInput.choice18.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label18
      } else if ( this.formInput.choice19.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label19
      } else if ( this.formInput.choice20.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label20
      } else {
        console.log(' answer is full! OK ')
        this.ChkErrorMsg = ''
      }

      if ( this.ChkErrorMsg.length !== 0 ) {
        this.isChkErrorMsg = true
      } else {
        this.isChkErrorMsg = false
      }
      return this.isChkErrorMsg
    },
    /******************************************************************************
    * 함수 : fn_RequestSuitaQstnInve
    * 설명 : 가입 고객의 적합성 정보 조회 - 적합성 입력정보 조회(S1) - invoke call
    ******************************************************************************/
    fn_RequestSuitaQstnInve () {
      var param = {
        cnsltNo: '',
        contrNm: this.custInfo.custNm,
        contrRrn: this.custInfo.knb
      }
      window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke('UF1MDI0291', param, this.fn_RequestSuitaQstnInveHandler, null, this.fn_RequestSuitaQstnInveHandler, this, this)
    },
    /********************************************************************************************
     * 함수 : fn_RequestSuitaQstnInveHandler
     * 설명 : 적합성 정보 조회(S1) 요청의 응답 처리
     ********************************************************************************************/
    fn_RequestSuitaQstnInveHandler (result) {
      if(!this.dcSuitaCnsntVO) this.dcSuitaCnsntVO = {}
      this.dcSuitaCnsntVO = result
      this.fn_prtSuitaInfo(result)
    },
    /********************************************************************************************
     * 함수 : fn_prtSuitaInfo
     * 설명 : 조회된 적합성 정보 출력
     * Param : pISuitaVO - 적합성 정보 조회(S1) 응답(Response) 정보
    ********************************************************************************************/
    fn_prtSuitaInfo (pISuitaVO) {
      // ASIS.주석처리
      // let rskEftAnlyYmd_Date = pISuitaVO.rskEftAnlyYmd
      // // 적합성 유효일자가 지나면 다시 입력
      // if ( pISuitaVO.rskEftAnlyYmd !== '' && rskEftAnlyYmd_Date > piCommonUtil.getCurrentDate()) {
      //   // 알림 팝업 표시'적합성진단 유효기간(3개월)이 만료되어 사용하실 수 없습니다. 적합성진단을 재수행 바랍니다.')
      //   this.isSuitaExpire = true
      //   pISuitaVO.inqrYn = 'N' // 다시 적합성 진단 수행이 필요함
      // }
      // (2021.11_PJO)
      let rskEftAnlyYmd_Date = pISuitaVO.rskEftAnlyYmd === undefined || ( String(pISuitaVO.rskEftAnlyYmd).trim() !== '' ) ? pISuitaVO.rskEftAnlyYmd : ''
      let toDay = ''
      if(window.vue.getStore('psStore')) {
        toDay = DateUtil.fn_CurrentDate()
      }else {
        toDay = DateUtil.fn_DateToString(new Date(), 'yyyyMMdd')
      }
      let expirDay = '' // 이날짜 부터 만료 (91일 이내에 입력된 적합성만 유효함)
      // CRM 예 ) TOBE : 최근 91일 입력내용   오늘이 2021.06.16 -> 2021.03.18 이후 부터 가능 (시작~종료일 포함해서 91일 )
      if ( rskEftAnlyYmd_Date !== undefined && String(rskEftAnlyYmd_Date).trim() !== '') {
        expirDay = DateUtil.addDate(rskEftAnlyYmd_Date, +91) // (ex:2021.01.01 + 91일 => 2021.04.02 로 나옴 / 유효한날짜는 2021.04.01 까지 여야함)
        console.log('>> toDay:' + toDay + ' / 진단일단:' + rskEftAnlyYmd_Date + ' / 만료시작일자:' + expirDay)
        if ( expirDay <= toDay ) {
          // 알림 팝업 표시'적합성진단 유효기간(91일)이 만료되어 사용하실 수 없습니다. 적합성진단을 재수행 바랍니다.')
          this.isSuitaExpire = true
          this.alertMsg = this.ChkExpirMsg
          this.$refs.refAlert.open()
          pISuitaVO.inqrYn = 'N' // 다시 적합성 진단 수행이 필요함
          // 하단 버튼 셋팅
          this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼
          this.isDisableBtnCmplt = true // 선택완료, 진단결과 발행 버튼
          this.isShowBtnOldDataUse = this.isShowBtnRetry = false // 기존정보 사용 , 선택완료 버튼
          this.cmpltBtnTxt = '선택완료'
          this.btnStatus1 = '1' // 1-선택완료, 2-진단결과발행
          window.vue.getStore('progress').dispatch('UPDATE', false)
          return
        }
      }

      // 적합성 재진단 여부체크
      this.isReSuita = false
      if ( pISuitaVO.rskEftAnlyYmd !== '' && pISuitaVO.rskEftAnlyYmd === piCommonUtil.getCurrentDate()) {
        this.isReSuita = true
      }
      // 적합성 재진단 여부체크 -> 2023-05-24 승인건이고 유효기간이 있으면 재진단 가능하게 변경 by 기획 김은비 과장
      // this.isReSuita = false
      // if ( pISuitaVO.rskEftAnlyYmd !== '' && pISuitaVO.inqrYn !== 'N' && pISuitaVO.aprvYn === 'Y') {
      //   this.isReSuita = true
      // }

      // 적합성보고서 발행용 버튼 처리
      this.closeButtonEnabled = true

      // 보고서 발행되어야 하는 경우
      if (this.STR_suitaReportYN === 'Y') {
        this.isDisable = false

        this.isDisableBtnExprt = true // 전문금융소비자
        this.isDisableBtnPlb = false // 진단결과 발행
        this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼 show
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
        this.isDisableBtnCmplt = false // 진단결과 발행 버튼 활성화
        this.isShowBtnOldDataUse = false // 기존정보 사용 버튼
        this.isShowBtnRetry = false // 다시진단하기, 변경 재진단 버튼

        // 보고서미발행시 닫기 불가
        if (this.STR_suitaReportPblYN !== 'Y' && pISuitaVO.prpnsDignDnlYn !== 'Y') {
          this.closeButtonEnabled = false // 보고서 발행이 되어야하는 경우는 닫지 못하게 하는 처리
        }
      }

      // 적합성 입력정보 조회
      // 1. 기존 설문정보가 있는 경우
      // ① 적합성승인을 받은 경우, (설계사상이에 상관없이)기존 설문조사정보를 표시한다.
      // ② 적합성승인을 안 받은 경우, 설계사가 상이 할 경우, 설문조사 초기화면을 표시한다.
      // 2. 기존 설문정보가 없는 경우, 설문조사 초기화면을 표시한다.
      if (pISuitaVO.inqrYn === 'Y') { // 조회여부 체크
        if (pISuitaVO.aprvYn === 'Y') { // 승인 여부 체크
          this.strMB_prextSuitaYn = 'Y' // 적합성 이력이 있음
          this.isDisableIns = true
        } else {
          if (this.headerVO.userId !== pISuitaVO.cnsltNo) {
          // if ( pISuitaVO.cnsltNo !== '0001057465' ) {
            this.strMB_prextSuitaYn = 'N'
            this.isDisableIns = false
            this.card00.detailAreaOpen = true
            window.vue.getStore('progress').dispatch('UPDATE', false)
            return
          }
          this.strMB_prextSuitaYn = 'N'
        }
      } else {
        this.strMB_prextSuitaYn = 'N'
        this.isDisableIns = false
        this.card00.detailAreaOpen = true
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      }

      this.aprvYn = pISuitaVO.aprvYn // 승인/미승인 여부
      // legacy PJT 변경. 적합성 진단 ID 매핑 추가.
      this.suitaDignId = pISuitaVO.suitaDignId

      // 성향진단을 거부했을 경우, 초기화면 표시
      // if (pISuitaVO.prpnsDignDnlYn !== 'Y') {
      // 전문가금융소비자여부, 전문가금융소비자가 아닌 경우, 초기화면 표시
      // if (pISuitaVO.exprtFncCspYn !== 'Y') {
      if (piCommonUtil.isObj(pISuitaVO)) {
        // 0. 전문금융소비자여부
        if ( pISuitaVO.exprtFncCspYn === 'Y' ) {
          this.formInput.choice01.push('2') // 01. 전문가금융소비자여부 (1:일반금융소비자, 2:전문금융소비자)
          this.isDisableBtnExprt = false // 전문금융소비자 버튼 활성
          this.c00 = '2'
          this.card00.selected = true
          this.card00.selectedValue = '전문금융소비자 / 확인완료'
          this.card00.detailAreaOpen = false
        } else {
          this.formInput.choice01.push('1') // 01. 전문가금융소비자여부 (1:일반금융소비자, 2:전문금융소비자)
          this.isDisableBtnExprt = true // 전문금융소비자 버튼 비활성
          this.c00 = '1'
          this.card00.selected = true
          this.card00.selectedValue = '일반금융소비자'
          this.card00.detailAreaOpen = false
        }
        // 0. 금융취약계층
        if ( pISuitaVO.fncCnsmRstcObjYn === 'Y' ) {
          this.formInput.choice02.push('1') // 해당없음
          this.isDisableLeglAgnt = true
        }
        if ( pISuitaVO.highAgeRskYn === 'Y' ) {
          this.formInput.choice02.push('2') // 해당(65세이상)
          this.isDisableLeglAgnt = true
        }
        if ( pISuitaVO.miorYn === 'Y' ) {
          this.formInput.choice02.push('3') // 해당(미성년자)
          this.isDisableLeglAgnt = false
        }
        if ( pISuitaVO.icmpYn === 'Y' ) {
          this.formInput.choice02.push('4') // 해당(정신적 장애 등)
          this.isDisableLeglAgnt = false
        }
        // 법정대리인여부
        this.formInput.choice03 = []
        if ( this.isDisableLeglAgnt === false ) {
          if ( pISuitaVO.leglAgntYn === 'Y') {
            this.formInput.choice03.push('1') // 03. 법정대리인여부 (1:예)
            this.c000 = '1'
          } else if ( pISuitaVO.leglAgntYn === 'N') {
            this.formInput.choice03.push('2') // 03. 법정대리인여부 (2:아니오)
            this.c000 = '2'
          }
        }
        // 금융취약계층 선택 텍스트 셋팅
        if(this.formInput.choice02.length > 0) {
          this.card000.selected = true
          this.card000.detailAreaOpen = false
          this.formInput.choice02.forEach(item => {
            if(item === '1') this.card000.selectedValue1 = '해당없음'
            else if(item === '2') this.card000.selectedValue1 = '해당(65세 이상)'
            else if(item === '3') this.card000.selectedValue1 = '해당(미성년자)'
            else if(item === '4') this.card000.selectedValue2 = '해당(정신적 장애 등)'
          })
        }
        // 1. 가입목적 금융상품
        this.formInput.choice1 = !pISuitaVO.entPpsFncPrdtQstnRslt ? { key: '0', label: '선택하세요' } : this.choice1Items[pISuitaVO.entPpsFncPrdtQstnRslt - 1] // 1. 가입목적 금융상품 설문결과
        this.c1 = this.formInput.choice1.key
        this.card1.selected = true
        this.card1.detailAreaOpen = false
        this.card1.selectedValue = this.choice1Items[pISuitaVO.entPpsFncPrdtQstnRslt - 1].label
        // 2. 변액보험 가입목적
        this.formInput.choice2 = !pISuitaVO.entPpsQstnRslt ? { key: '0', label: '선택하세요' } : this.choice2Items[pISuitaVO.entPpsQstnRslt - 1] // 2. 변액보험 가입목적 설문결과
        this.c2 = this.formInput.choice2.key
        this.card2.selected = true
        this.card2.detailAreaOpen = false
        this.card2.selectedValue = this.choice2Items[pISuitaVO.entPpsQstnRslt - 1].label
        // 3. 월 평균 소득
        this.formInput.choice3 = !pISuitaVO.mntAvrIncmQstnRslt ? { key: '0', label: '선택하세요' } : this.choice3Items[pISuitaVO.mntAvrIncmQstnRslt - 1] // 3. 월평균 소득 설문결과
        this.c3 = this.formInput.choice3.key
        this.card3.selected = true
        this.card3.detailAreaOpen = false
        this.card3.selectedValue = this.choice3Items[pISuitaVO.mntAvrIncmQstnRslt - 1].label
        // 4. 월 평균 소득대비 보험료 비중
        this.formInput.choice4 = !pISuitaVO.incmCmpsnPrmQstnRslt ? { key: '0', label: '선택하세요' } : this.choice4Items[pISuitaVO.incmCmpsnPrmQstnRslt - 1] // 4. 월평균 소득比 보험료 비중 설문결과
        this.c4 = this.formInput.choice4.key
        this.card4.selected = true
        this.card4.detailAreaOpen = false
        this.card4.selectedValue = this.choice4Items[pISuitaVO.incmCmpsnPrmQstnRslt - 1].label
        // 5. 보혐료 재원 순자산
        this.formInput.choice5 = !pISuitaVO.netAsetAmtQstnRslt ? { key: '0', label: '선택하세요' } : this.choice5Items[pISuitaVO.netAsetAmtQstnRslt - 1] // 5. 보험료 재원 순자산 설문결과
        this.c5 = this.formInput.choice5.key
        this.card5.selected = true
        this.card5.detailAreaOpen = false
        this.card5.selectedValue = this.choice5Items[pISuitaVO.netAsetAmtQstnRslt - 1].label
        // 6. 추가 납부 가능금액
        this.formInput.choice6 = !pISuitaVO.addmPadPrmQstnRslt ? { key: '0', label: '선택하세요' } : this.choice6Items[pISuitaVO.addmPadPrmQstnRslt - 1] // 6. 추가납부 가능금액 설문결과
        this.c6 = this.formInput.choice6.key
        this.card6.selected = true
        this.card6.detailAreaOpen = false
        this.card6.selectedValue = this.choice6Items[pISuitaVO.addmPadPrmQstnRslt - 1].label
        // 7. 보험료 납입가능기간
        this.formInput.choice7 = !pISuitaVO.padPrdQstnRslt ? { key: '0', label: '선택하세요' } : this.choice7Items[pISuitaVO.padPrdQstnRslt - 1] // 7. 보험료 납입가능기간 설문결과
        this.c7 = this.formInput.choice7.key
        this.card7.selected = true
        this.card7.detailAreaOpen = false
        this.card7.selectedValue = this.choice7Items[pISuitaVO.padPrdQstnRslt - 1].label
        // 8. 예상 유지기간
        this.formInput.choice8 = !pISuitaVO.ukepPrdQstnRslt ? { key: '0', label: '선택하세요' } : this.choice8Items[pISuitaVO.ukepPrdQstnRslt - 1] // 8. 예상 유지기간 설문결과
        this.c8 = this.formInput.choice8.key
        this.card8.selected = true
        this.card8.detailAreaOpen = false
        this.card8.selectedValue = this.choice8Items[pISuitaVO.ukepPrdQstnRslt - 1].label
        // 9. 중도해지 가능성
        this.formInput.choice9 = !pISuitaVO.midRscPsblyQstnRslt ? { key: '0', label: '선택하세요' } : this.choice9Items[pISuitaVO.midRscPsblyQstnRslt - 1] // 9. 중도해지 가능성 설문결과
        this.c9 = this.formInput.choice9.key
        this.card9.selected = true
        this.card9.detailAreaOpen = false
        this.card9.selectedValue = this.choice9Items[pISuitaVO.midRscPsblyQstnRslt - 1].label
        // 10. 투자경험기간
        this.formInput.choice10 = !pISuitaVO.ivtExprPrdQstnRslt ? { key: '0', label: '선택하세요' } : this.choice10Items[pISuitaVO.ivtExprPrdQstnRslt - 1] // 10. 투자경험기간 설문결과
        this.c10 = this.formInput.choice10.key
        this.card10.selected = true
        this.card10.detailAreaOpen = false
        this.card10.selectedValue = this.choice10Items[pISuitaVO.ivtExprPrdQstnRslt - 1].label
        // 11. 가입경험상품 (최근 2년 이내)
        if(pISuitaVO.ivtExprStckMngQstnRslt === 'Y') this.formInput.choice11.push('1') // 주식(관리, 위험종목)등
        if(pISuitaVO.ivtExprStckGenQstnRslt === 'Y') this.formInput.choice11.push('2') // 주식(일반종목), 주식형 펀드(시장수익률)등
        if(pISuitaVO.ivtExprBlendQstnRslt === 'Y') this.formInput.choice11.push('3') // 혼합형 펀드 등
        if(pISuitaVO.ivtExprBtypQstnRslt === 'Y') this.formInput.choice11.push('4') // 채권형 펀드 등
        if(pISuitaVO.ivtExprVainsQstnRslt === 'Y') this.formInput.choice11.push('5') // 변액보험
        if(pISuitaVO.ivtExprNhvQstnRslt === 'Y') this.formInput.choice11.push('6') // 투자경험 없음
        // 가입경험상품 선택 텍스트 셋팅
        if(this.formInput.choice11.length > 0) {
          this.card11.selected = true
          this.card11.detailAreaOpen = false
          this.formInput.choice11.forEach(item => {
            if(item === '1') this.card11.selectedValue1 = this.choice11Items[Number(item) - 1].label
            else if(item === '2') this.card11.selectedValue2 = this.choice11Items[Number(item) - 1].label
            else if(item === '3') this.card11.selectedValue3 = this.choice11Items[Number(item) - 1].label
            else if(item === '4') this.card11.selectedValue4 = this.choice11Items[Number(item) - 1].label
            else if(item === '5') this.card11.selectedValue5 = this.choice11Items[Number(item) - 1].label
            else if(item === '6') this.card11.selectedValue6 = this.choice11Items[Number(item) - 1].label
          })
        }
        // 12. 투자경험 목적
        if(pISuitaVO.qstnIvtNhvExprYn === 'Y') this.formInput.choice12.push('1') // 투자경험없음
        if(pISuitaVO.qstnExprSprdFrtunYn === 'Y') this.formInput.choice12.push('2') // 단기재산증식
        if(pISuitaVO.qstnExprLtmSavYn === 'Y') this.formInput.choice12.push('3') // 장기저축을 통한 목돈 마련
        if(pISuitaVO.qstnExprAnutAsetYn === 'Y') this.formInput.choice12.push('4') // 노후를 위한 연금자산 마련
        if(pISuitaVO.qstnExprCovrAsetYn === 'Y') this.formInput.choice12.push('5') // 위험에 대비한 가족의 보장자산 마련
        // 투자경험 목적 선택 텍스트 셋팅
        if(this.formInput.choice12.length > 0) {
          this.card12.selected = true
          this.card12.detailAreaOpen = false
          this.formInput.choice12.forEach(item => {
            if(item === '1') this.card12.selectedValue1 = this.choice12Items[Number(item) - 1].label
            else if(item === '2') this.card12.selectedValue2 = this.choice12Items[Number(item) - 1].label
            else if(item === '3') this.card12.selectedValue3 = this.choice12Items[Number(item) - 1].label
            else if(item === '4') this.card12.selectedValue4 = this.choice12Items[Number(item) - 1].label
            else if(item === '5') this.card12.selectedValue5 = this.choice12Items[Number(item) - 1].label
          })
        }
        // 13. 투자경험 금액규모 (최근2년 이내)
        this.formInput.choice13 = !pISuitaVO.qstnIvtExprAmtScleCd ? { key: '0', label: '선택하세요' } : this.choice13Items[pISuitaVO.qstnIvtExprAmtScleCd - 1] // 13. 투자경험 금액규모 설문결과
        this.c13 = this.formInput.choice13.key
        this.card13.selected = true
        this.card13.detailAreaOpen = false
        this.card13.selectedValue = this.choice13Items[pISuitaVO.qstnIvtExprAmtScleCd - 1].label
        // 14. 투자경험 거래빈도 (최근2년 이내)
        this.formInput.choice14 = !pISuitaVO.qstnIvtExprDlFrqnCd ? { key: '0', label: '선택하세요' } : this.choice14Items[pISuitaVO.qstnIvtExprDlFrqnCd - 1] // 14. 투자경험 거래빈도 설문결과
        this.c14 = this.formInput.choice14.key
        this.card14.selected = true
        this.card14.detailAreaOpen = false
        this.card14.selectedValue = this.choice14Items[pISuitaVO.qstnIvtExprDlFrqnCd - 1].label
        // 15. 변액보험 등 금융상품 이해수준
        this.formInput.choice15 = !pISuitaVO.qstnVainsAppreLvCd ? { key: '0', label: '선택하세요' } : this.choice15Items[pISuitaVO.qstnVainsAppreLvCd - 1] // 15. 변액보험 이해수준 설문결과
        this.c15 = this.formInput.choice15.key
        this.card15.selected = true
        this.card15.detailAreaOpen = false
        this.card15.selectedValue = this.choice15Items[pISuitaVO.qstnVainsAppreLvCd - 1].label
        // 16. 위험성향
        this.formInput.choice16 = !pISuitaVO.rskPrpnsQstnRslt ? { key: '0', label: '선택하세요' } : this.choice16Items[pISuitaVO.rskPrpnsQstnRslt - 1] // 16. 위험성향 설문결과
        this.c16 = this.formInput.choice16.key
        this.card16.selected = true
        this.card16.detailAreaOpen = false
        this.card16.selectedValue = this.choice16Items[pISuitaVO.rskPrpnsQstnRslt - 1].label
        // 17. 기대수익률
        this.formInput.choice17 = !pISuitaVO.atcptBnfRatQstnRslt ? { key: '0', label: '선택하세요' } : this.choice17Items[pISuitaVO.atcptBnfRatQstnRslt - 1] // 17. 기대수익율 설문결과
        this.c17 = this.formInput.choice17.key
        this.card17.selected = true
        this.card17.detailAreaOpen = false
        this.card17.selectedValue = this.choice17Items[pISuitaVO.atcptBnfRatQstnRslt - 1].label
        // 18. 손실감내수준
        this.formInput.choice18 = !pISuitaVO.losLvQstnRslt ? { key: '0', label: '선택하세요' } : this.choice18Items[pISuitaVO.losLvQstnRslt - 1] // 18. 손실감내수준 설문결과
        this.c18 = this.formInput.choice18.key
        this.card18.selected = true
        this.card18.detailAreaOpen = false
        this.card18.selectedValue = this.choice18Items[pISuitaVO.losLvQstnRslt - 1].label
        // 19. 주식형비중
        this.formInput.choice19 = !pISuitaVO.stckRtoQstnRslt ? { key: '0', label: '선택하세요' } : this.choice19Items[pISuitaVO.stckRtoQstnRslt - 1] // 19. 주식형비중 설문결과
        this.c19 = this.formInput.choice19.key
        this.card19.selected = true
        this.card19.detailAreaOpen = false
        this.card19.selectedValue = this.choice19Items[pISuitaVO.stckRtoQstnRslt - 1].label
        // 20. 투자상품 보유비중
        this.formInput.choice20 = !pISuitaVO.qstnIvtPrdtRtoCd ? { key: '0', label: '선택하세요' } : this.choice20Items[pISuitaVO.qstnIvtPrdtRtoCd - 1] // 20. 투자상품 보유비중 설문결과
        this.c20 = this.formInput.choice20.key
        this.card20.selected = true
        this.card20.detailAreaOpen = false
        this.card20.selectedValue = this.choice20Items[pISuitaVO.qstnIvtPrdtRtoCd - 1].label

        // 전문금융소비자 정보 데이터 셋팅
        this.SuitaExportVO.exprtFncCspYn = pISuitaVO.exprtFncCspYn ? pISuitaVO.exprtFncCspYn : 'N' // 전문가금융소비자여부
        this.SuitaExportVO.exprtFncCspTypCd = pISuitaVO.exprtFncCspTypCd ? pISuitaVO.exprtFncCspTypCd : '' // 전문가금융소비자유형코드
        this.SuitaExportVO.genFncCspSameTrtmtYn = pISuitaVO.genFncCspSameTrtmtYn ? pISuitaVO.genFncCspSameTrtmtYn : 'N' // 일반금융소비자동일대우여부
        // 전문금융소비자 정보 배열에 저장
        this.fn_setExportArray()

        this.fn_prtSutaResult(pISuitaVO)
      } else {
        // 전문가금융소비자일 경우
        this.formInput.choice01.push('2') // 01. 전문가금융소비자여부
        // 성향진단 거부일 경우
        this.strMB_prextSuitaYn = 'N'
        // 알림 팝업 표시 '부적절한 방법으로 위험성향진단 거부를\n유도했을 경우 보험업법 및 자본시장법 위반입니다.\n(지점장에게 승인요청 하시기 바랍니다)
        this.isPrpnsDignDnl = false

        // 분석결과 출력
        this.lbRskEftAnlyRslt = '진단거부'
        this.lbInsrAnlyRslt = '진단거부'
        // 분석결과 날짜(기간값) 설정
        // let todayDateAfter3M = DateUtil.fn_AddMonth(pISuitaVO.rskEftAnlyYmd, 3)
        let todayDateAfter3M = DateUtil.addDate(pISuitaVO.rskEftAnlyYmd, +90) // 91일이후날짜(91일이 맞지만, +1일이되서 계산됨/만료일자계산은 90일로 세팅함) 2021.11_PJO
        this.lbInsrAnlyRslt_Date = '( ~ ' + DateUtil.fn_DateFormat(todayDateAfter3M, 'yyyy-MM-dd') + ')'
        this.lbRskEftAnlyRslt_Date = this.lbInsrAnlyRslt_Date
        // 이전에 저장된 금융전문가 정보를 표기
        this.SuitaExportVO.exprtFncCspYn = pISuitaVO.exprtFncCspYn ? pISuitaVO.exprtFncCspYn : 'N' // 전문가금융소비자여부
        this.SuitaExportVO.exprtFncCspTypCd = pISuitaVO.exprtFncCspTypCd ? pISuitaVO.exprtFncCspTypCd : '' // 전문가금융소비자유형코드
        this.SuitaExportVO.genFncCspSameTrtmtYn = pISuitaVO.genFncCspSameTrtmtYn ? pISuitaVO.genFncCspSameTrtmtYn : 'N' // 일반금융소비자동일대우여부
        // 전문금융소비자 정보 배열에 저장
        this.fn_setExportArray()
        // 승인/미승인 라벨 출력
        this.fn_setAprvLabel(pISuitaVO)
        // 법인대표자 정보 저장 처리
        this.fn_setPlanSuitaResntInfo(pISuitaVO)
      }
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /********************************************************************************************
     * 함수 : fn_setExportArray
     * 설명 : 성향 진단 값 배열에 저장
    ********************************************************************************************/
    fn_setExportArray () {
      this.SuitaExportArray = []

      if ( this.SuitaExportVO.exprtFncCspYn === 'Y' ) {
        this.SuitaExportArray.push(this.SuitaExportVO.exprtFncCspTypCd)
        if ( this.SuitaExportVO.genFncCspSameTrtmtYn === 'Y' ) this.SuitaExportArray.push('4')
      }
    },
    /*********************************************************************************************
    * 함수 : fn_prtSutaResult()
    * 설명 : 가입불가대상판단여부와 위험성향분석결과코드로 분석결과 메시지를 설정
    *********************************************************************************************/
    fn_prtSutaResult (pISuitaVO) {
      // 가입불가대상판단여부와 위험성향분석결과코드로 분석결과 메시지를 설정한다.
      this.entDalwObjJdgmtYn = pISuitaVO.entDalwObjJdgmtYn // 가입불가대상판단여부
      this.rskEftAnlyRslt = pISuitaVO.rskEftAnlyRslt // 위험영향분석결과(11:위험회피형,12:안정형,13:중립형 ,14:위험선호형, 15:공격형, 99:진단거부)
      if ( pISuitaVO.rskEftAnlyYmd === '' ) {
        pISuitaVO.rskEftAnlyYmd = piCommonUtil.getCurrentDate()
      }
      // 위험영향분석결과
      this.fn_setRskEftAnlyRsltMsg(pISuitaVO)
      // 가입목적 변경관련
      this.MB_entPpsQstnRslt = pISuitaVO.entPpsQstnRslt

      if ( this.aprvYn === 'Y' ) { // 승인건만 발행버튼 활성화 (20220118_PJO)
        this.isDisableBtnPlb = false // 발행 버튼
        this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼 show
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
        this.isDisableBtnCmplt = false // 진단결과 발행 버튼 활성화
        this.isShowBtnOldDataUse = false // 기존정보 사용 버튼
        this.isShowBtnRetry = false // 다시진단하기, 변경 재진단 버튼
      }

      // ASR210701055_적합성 재진단을 위한 시스템 변경 요청 (사랑ON)
      // 당일 진단 승인건이 있는 경우, 재진단 팝업 호출
      if (this.isReSuita && this.aprvYn === 'Y') {
        if(!this.oldDataUseFlag) {
          this.isTSSPI723P = true
          this.isDisableBtnCmplt = true // (4) 재진단사유를 입력하지 않으면 '선택완료' 버튼 비활성화  -> 재진단팝업닫고 재진단사유 값이 있으면 버튼 활성화 됨 (fn_ConfirmTSSPI723P )/(20220118_PJO)
          this.$refs.refDignRsnAlert.open()
        }else {
          setTimeout(()=>{this.oldDataUseFlag = false},700)
          // this.oldDataUseFlag = false
        }
      } else if (!this.isReSuita && this.aprvYn === 'Y') {
        // 당일 진단 승인건이 아닌경우 하단 버튼 '기존정보사용', '입력완료' 활성화 한다.
        if(!this.oldDataUseFlag) {
          this.isShowBtnCmplt = false
          this.isShowBtnOldDataUse = this.isShowBtnRetry = true
          this.isDisableBtnCmplt = false
          // 초기화
          this.rDignYn = '' // 재진단여부
          this.rDignRsnCd = '' // 재진단사유코드
        }else {
          setTimeout(()=>{this.oldDataUseFlag = false},700)
        }
      } else if ( this.strMB_prextSuitaYn === 'N' && this.custInfo.contrRrnBkno.substr(0, 6) !== '000000') {
        // 신규 설문조사의 경우, 지점장 승인요청 메시지를 표시한다.(임시고객일 경우 체크 예외)
        this.isBmngrMsg = true
        this.alertMsg = this.reqConfirmFromBmngrMsg
        this.$refs.refAlert.open()
        this.subBtn11 = this.subBtn12 = true
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
        if(this.btnStatus1 === '1') this.isDisableBtnCmplt = true // 적합성선택완료 비활성화 (20220118_PJO)
      } else {
        if(this.aprvYn === 'N') {
          this.isBmngrMsg = true
          this.alertMsg = this.reqConfirmFromBmngrMsg
          this.$refs.refAlert.open()
          this.subBtn11 = this.subBtn12 = true
          this.cmpltBtnTxt = '진단결과 발행'
          this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
          if(this.btnStatus1 === '1') this.isDisableBtnCmplt = true // 적합성선택완료 비활성화 (20220118_PJO)
        }
      }

      // 법인대표자 정보 저장 처리
      this.fn_setPlanSuitaResntInfo()
    },
    /********************************************************************************************
     * 함수 : fn_setPlanSuitaResntInfo
     * 설명 : 법인대표자 정보 저장 처리
    ********************************************************************************************/
    fn_setPlanSuitaResntInfo () {
      if ( this.zzcusDtlTypCd === '21') {
        this.rltnrInfo.ageatentryVl = this.insrAge // 보험나이
      }

      let rltnrInfo_11 = this.rltnrInfo
      if ( rltnrInfo_11 !== undefined && rltnrInfo_11 !== undefined && rltnrInfo_11.zzcusDtlTypCd === '21') { // 계약자가 단체일경우
        let abdapolicyList = Gaf.getGafObjByRefId('POLICY') // Policy 정보 Array
        if (abdapolicyList !== undefined && abdapolicyList.length > 0) {
          let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
          let abdapolhldr = abdapolicy.abdapolhldr[0]
          let ztpqlCboextAddmAbdapolhldr = abdapolhldr.ztpqlCboextAddmAbdapolhldr

          // 계약자가 단체일경우에는 단체명을 셋팅함
          if (rltnrInfo_11.zzcusDtlTypCd === '21' && !this.$bizUtil.isEmpty(ztpqlCboextAddmAbdapolhldr.zaGNm)) {
            // 법인대표자명
            ztpqlCboextAddmAbdapolhldr.zaResntNm = this.custInfo.custNm // this.contrNm
            // 법인대표자주민번호
            ztpqlCboextAddmAbdapolhldr.zaResntRrnId = this.custInfo.contrRrnFrno + this.custInfo.contrRrnBkno // this.contrRrnFrno + this.contrRrnBkno
            // 법인대표자 Agent File ID
            ztpqlCboextAddmAbdapolhldr.zaRentAgtFileId = this.custInfo.chnlCustId
          }
        }
      }
    },
    /********************************************************************************************
     * 함수 : fn_setData
     * 설명 : 금융 취약 계층 답변, 설문지에 작성한 답안 정보 설정
    ********************************************************************************************/
    fn_setData () {
      this.fncCnsm.fncCnsmRstcObjYn = 'N'
      this.fncCnsm.highAgeRskYn = 'N'
      this.fncCnsm.miorYn = 'N'
      this.fncCnsm.icmpYn = 'N'

      for ( let i = 0; i < this.formInput.choice02.length; i++ ) {
        if ( this.formInput.choice02[i] === '1' ) {
          this.fncCnsm.fncCnsmRstcObjYn = 'Y' // 취약금융소비자해당여부
        } else if ( this.formInput.choice02[i] === '2' ) {
          this.fncCnsm.highAgeRskYn = 'Y' // 고연령위험여부
        } else if ( this.formInput.choice02[i] === '3' ) {
          this.fncCnsm.miorYn = 'Y' // 미성년자여부
        } else if ( this.formInput.choice02[i] === '4' ) {
          this.fncCnsm.icmpYn = 'Y' // 금치산자여부
        } else {
          console.log('Out of Value')
        }
      }
    },
    /********************************************************************************************
     * 함수 : fn_setData2
     * 설명 : 가입경험상품 설문지에 작성한 답안 정보 설정
    ********************************************************************************************/
    fn_setData2 () {
      // 11. 투자경험주식관리 설문결과
      this.ivtExpr.ivtExprStckMngQstnRslt = 'N'
      this.ivtExpr.ivtExprStckGenQstnRslt = 'N'
      this.ivtExpr.ivtExprBlendQstnRslt = 'N'
      this.ivtExpr.ivtExprBtypQstnRslt = 'N'
      this.ivtExpr.ivtExprVainsQstnRslt = 'N'
      this.ivtExpr.ivtExprNhvQstnRslt = 'N'

      for ( let i = 0; i < this.formInput.choice11.length; i++ ) {
        if ( this.formInput.choice11[i] === '1' ) {
          this.ivtExpr.ivtExprStckMngQstnRslt = 'Y' // 투자경험주식관리설문결과
        } else if ( this.formInput.choice11[i] === '2' ) {
          this.ivtExpr.ivtExprStckGenQstnRslt = 'Y' // 투자경험주식일반설문결과
        } else if ( this.formInput.choice11[i] === '3' ) {
          this.ivtExpr.ivtExprBlendQstnRslt = 'Y' // 투자경험혼합설문결과
        } else if ( this.formInput.choice11[i] === '4' ) {
          this.ivtExpr.ivtExprBtypQstnRslt = 'Y' // 투자경험채권형설문결과
        } else if ( this.formInput.choice11[i] === '5' ) {
          this.ivtExpr.ivtExprVainsQstnRslt = 'Y' // 투자경험변액설문결과
        } else if ( this.formInput.choice11[i] === '6' ) {
          this.ivtExpr.ivtExprNhvQstnRslt = 'Y' // 투자경험무설문결과
        } else {
          console.log('Out of Value')
        }
      }

      // 12. 투자경험 목적 설문결과
      this.qstnExpr.qstnIvtNhvExprYn = 'N'
      this.qstnExpr.qstnExprSprdFrtunYn = 'N'
      this.qstnExpr.qstnExprLtmSavYn = 'N'
      this.qstnExpr.qstnExprAnutAsetYn = 'N'
      this.qstnExpr.qstnExprCovrAsetYn = 'N'

      for ( let i = 0; i < this.formInput.choice12.length; i++ ) {
        if ( this.formInput.choice12[i] === '1' ) { // 설문투자무경험여부
          this.qstnExpr.qstnIvtNhvExprYn = 'Y'
        }
        if ( this.formInput.choice12[i] === '2' ) { // 설문경험단기재산여부
          this.qstnExpr.qstnExprSprdFrtunYn = 'Y'
        }
        if ( this.formInput.choice12[i] === '3' ) { // 설문경험장기저축여부
          this.qstnExpr.qstnExprLtmSavYn = 'Y'
        }
        if ( this.formInput.choice12[i] === '4' ) { // 설문경험연금자산여부
          this.qstnExpr.qstnExprAnutAsetYn = 'Y'
        }
        if ( this.formInput.choice12[i] === '5' ) { // 설문경험보장자산여부
          this.qstnExpr.qstnExprCovrAsetYn = 'Y'
        }
      }
    },
    /********************************************************************************************
     * 함수 : fn_setSuitaAnswerData
     * 설명 : 적합성 입력 완료 -> 적합성 심사 요청을 하기위한 입력값 VO(dcSuitaCnsntVO)에 넣기
    ********************************************************************************************/
    fn_setSuitaAnswerData () {
      this.fn_setData()
      this.fn_setData2()

      if(!this.dcSuitaCnsntVO) this.dcSuitaCnsntVO = {}

      this.dcSuitaCnsntVO = {
        // 계약정보
        contrNm: this.custInfo.custNm, // 계약자명
        contrRrn: this.custInfo.knb, // 계약자 주민번호
        insrAge: this.custInfo.age, // 계약자연령

        // 금융전문가
        exprtFncCspYn: this.formInput.choice01[0] === '1' ? 'N' : 'Y', // 01. 전문가금융소비자여부 (1:일반, 2:전문)
        exprtFncCspTypCd: this.formInput.choice01[0] === '1' ? '' : this.SuitaExportVO.exprtFncCspTypCd, // 01. 전문가금융소비자유형코드
        genFncCspSameTrtmtYn: this.formInput.choice01[0] === '1' ? '' : this.SuitaExportVO.genFncCspSameTrtmtYn, // 01. 일반금융소비자동일대우여부

        // 취약금융소비자
        fncCnsmRstcObjYn: this.fncCnsm.fncCnsmRstcObjYn, // 02. 취약금융소비자해당여부
        highAgeRskYn: this.fncCnsm.highAgeRskYn, // 02. 고연령위험여부
        miorYn: this.fncCnsm.miorYn, // 02. 미성년자여부
        icmpYn: this.fncCnsm.icmpYn, // 02. 정신적 장애, 기타 등(금치산자여부)
        leglAgntYn: this.fncCnsm.leglAgntYn === '1' ? 'Y' : this.fncCnsm.leglAgntYn === '2' ? 'N' : '', // 02. 법적대리인여부

        // 설문 내용
        entPpsFncPrdtQstnRslt: this.formInput.choice1.key, // 1. 가입목적금융상품설문결과
        entPpsQstnRslt: this.formInput.choice2.key, // 2. 가입목적설문결과
        mntAvrIncmQstnRslt: this.formInput.choice3.key, // 3. 월평균소득설문결과
        incmCmpsnPrmQstnRslt: this.formInput.choice4.key, // 4. 소득대비보험료설문결과
        netAsetAmtQstnRslt: this.formInput.choice5.key, // 5. 순자산금액설문결과
        addmPadPrmQstnRslt: this.formInput.choice6.key, // 6. 추가납입보험료설문결과
        padPrdQstnRslt: this.formInput.choice7.key, // 7. 납입기간설문결과
        ukepPrdQstnRslt: this.formInput.choice8.key, // 8. 유지기간설문결과
        midRscPsblyQstnRslt: this.formInput.choice9.key, // 9. 중도해지가능성설문결과
        ivtExprPrdQstnRslt: this.formInput.choice10.key, // 10.  투자경험기간설문결과
        ivtExprStckMngQstnRslt: this.ivtExpr.ivtExprStckMngQstnRslt, // 11. 투자경험주식관리설문결과
        ivtExprStckGenQstnRslt: this.ivtExpr.ivtExprStckGenQstnRslt, // 11. 투자경험주식일반설문결과
        ivtExprBlendQstnRslt: this.ivtExpr.ivtExprBlendQstnRslt, // 11. 투자경험혼합설문결과
        ivtExprBtypQstnRslt: this.ivtExpr.ivtExprBtypQstnRslt, // 11. 투자경험채권형설문결과
        ivtExprVainsQstnRslt: this.ivtExpr.ivtExprVainsQstnRslt, // 11. 투자경험변액설문결과
        ivtExprNhvQstnRslt: this.ivtExpr.ivtExprNhvQstnRslt, // 11. 투자경험무설문결과

        // 12. 투자경험 목적 설문결과
        qstnIvtNhvExprYn: this.qstnExpr.qstnIvtNhvExprYn, // this.formInput.choice12.key === '1' ? 'Y' : 'N', // 12. 위험성향설문결과: 설문투자무경험여부
        qstnExprSprdFrtunYn: this.qstnExpr.qstnExprSprdFrtunYn, // this.formInput.choice12.key === '2' ? 'Y' : 'N', // 12. 위험성향설문결과: 설문경험단기재산여부
        qstnExprLtmSavYn: this.qstnExpr.qstnExprLtmSavYn, // this.formInput.choice12.key === '3' ? 'Y' : 'N', // 12. 위험성향설문결과: 설문경험장기저축여부
        qstnExprAnutAsetYn: this.qstnExpr.qstnExprAnutAsetYn, // this.formInput.choice12.key === '4' ? 'Y' : 'N', // 12. 위험성향설문결과: 설문경험연금자산여부
        qstnExprCovrAsetYn: this.qstnExpr.qstnExprCovrAsetYn, // this.formInput.choice12.key === '5' ? 'Y' : 'N', // 12. 위험성향설문결과: 설문경험보장자산여부

        // 설문 내용
        qstnIvtExprAmtScleCd: this.formInput.choice13.key, // 13. 투자경험 금액규모 설문결과
        qstnIvtExprDlFrqnCd: this.formInput.choice14.key, // 14. 투자경험 거래빈도 설문결과
        qstnVainsAppreLvCd: this.formInput.choice15.key, // 15. 변액보험 이해수준 설문결과
        rskPrpnsQstnRslt: this.formInput.choice16.key, // 16. 위험성향 설문결과
        atcptBnfRatQstnRslt: this.formInput.choice17.key, // 17. 기대수익율 설문결과
        losLvQstnRslt: this.formInput.choice18.key, // 18. 손실감내수준 설문결과
        stckRtoQstnRslt: this.formInput.choice19.key, // 19. 주식형비중 설문결과
        qstnIvtPrdtRtoCd: this.formInput.choice20.key // 20. 투자상품 보유비중 설문결과
      }
      this.MB_entPpsQstnRslt = this.formInput.choice2.key // 가입목적설문결과
      this.MB_entDalwObjJdgmt = 'N' // 가입불가대상판

      console.log('>>>>>>>>', this.dcSuitaCnsntVO)
    },
    /***********************************************************************
     * 함수 : fn_RequestSuitaDign
     * 설명 : 분석결과에 대한 진단 요청 - ( S2 ) 처리
     ***********************************************************************/
    fn_RequestSuitaDign () {
      var param = {
        cnsltNo: '',
        contrNm: this.custInfo.custNm,
        contrRrn: this.custInfo.contrRrnFrno + this.custInfo.contrRrnBkno,
        // 조회 전에 적합성ID 초기화.
        suitaDignId: 'asdf',

        rDignYn: this.rDignYn, // 재진단대상여부
        rDignRsnCd: this.rDignRsnCd, // 재진단 사유(01: 사실관계 착오 / 02: 단순오기)

        // 금융전문가
        exprtFncCspYn: this.dcSuitaCnsntVO.exprtFncCspYn, // 01. 전문가금융소비자여부
        exprtFncCspTypCd: this.dcSuitaCnsntVO.exprtFncCspTypCd, // 01. 전문가금융소비자유형코드
        genFncCspSameTrtmtYn: this.dcSuitaCnsntVO.genFncCspSameTrtmtYn, // 01. 일반금융소비자동일대우여부

        // 취약금융소비자
        fncCnsmRstcObjYn: this.dcSuitaCnsntVO.fncCnsmRstcObjYn, // 02. 취약금융소비자해당여부
        highAgeRskYn: this.dcSuitaCnsntVO.highAgeRskYn, // 02. 고연령위험여부
        miorYn: this.dcSuitaCnsntVO.miorYn, // 02. 미성년자여부
        icmpYn: this.dcSuitaCnsntVO.icmpYn, // 02. 정신적 장애, 기타 등(금치산자여부)
        leglAgntYn: this.dcSuitaCnsntVO.leglAgntYn, // 02. 법적대리인여부

        // 설문 내용
        entPpsFncPrdtQstnRslt: this.dcSuitaCnsntVO.entPpsFncPrdtQstnRslt, // 1. 가입목적금융상품설문결과
        entPpsQstnRslt: this.dcSuitaCnsntVO.entPpsQstnRslt, // 2. 가입목적설문결과
        mntAvrIncmQstnRslt: this.dcSuitaCnsntVO.mntAvrIncmQstnRslt, // 3. 월평균소득설문결과
        incmCmpsnPrmQstnRslt: this.dcSuitaCnsntVO.incmCmpsnPrmQstnRslt, // 4. 소득대비보험료설문결과
        netAsetAmtQstnRslt: this.dcSuitaCnsntVO.netAsetAmtQstnRslt, // 5. 순자산금액설문결과
        addmPadPrmQstnRslt: this.dcSuitaCnsntVO.addmPadPrmQstnRslt, // 6. 추가납입보험료설문결과
        padPrdQstnRslt: this.dcSuitaCnsntVO.padPrdQstnRslt, // 7. 납입기간설문결과
        ukepPrdQstnRslt: this.dcSuitaCnsntVO.ukepPrdQstnRslt, // 8. 유지기간설문결과
        midRscPsblyQstnRslt: this.dcSuitaCnsntVO.midRscPsblyQstnRslt, // 9. 중도해지가능성설문결과
        ivtExprPrdQstnRslt: this.dcSuitaCnsntVO.ivtExprPrdQstnRslt, // 10.  투자경험기간설문결과
        ivtExprStckMngQstnRslt: this.dcSuitaCnsntVO.ivtExprStckMngQstnRslt, // 11. 투자경험주식관리설문결과
        ivtExprStckGenQstnRslt: this.dcSuitaCnsntVO.ivtExprStckGenQstnRslt, // 11. 투자경험주식일반설문결과
        ivtExprBlendQstnRslt: this.dcSuitaCnsntVO.ivtExprBlendQstnRslt, // 11. 투자경험혼합설문결과
        ivtExprBtypQstnRslt: this.dcSuitaCnsntVO.ivtExprBtypQstnRslt, // 11. 투자경험채권형설문결과
        ivtExprVainsQstnRslt: this.dcSuitaCnsntVO.ivtExprVainsQstnRslt, // 11. 투자경험변액설문결과
        ivtExprNhvQstnRslt: this.dcSuitaCnsntVO.ivtExprNhvQstnRslt, // 11. 투자경험무설문결과

        // 12. 투자경험 목적 설문결과
        qstnIvtNhvExprYn: this.dcSuitaCnsntVO.qstnIvtNhvExprYn, // 12. 위험성향설문결과: 설문투자무경험여부
        qstnExprSprdFrtunYn: this.dcSuitaCnsntVO.qstnExprSprdFrtunYn, // 12. 위험성향설문결과: 설문경험단기재산여부
        qstnExprLtmSavYn: this.dcSuitaCnsntVO.qstnExprLtmSavYn, // 12. 위험성향설문결과: 설문경험장기저축여부
        qstnExprAnutAsetYn: this.dcSuitaCnsntVO.qstnExprAnutAsetYn, // 12. 위험성향설문결과: 설문경험연금자산여부
        qstnExprCovrAsetYn: this.dcSuitaCnsntVO.qstnExprCovrAsetYn, // 12. 위험성향설문결과: 설문경험보장자산여부

        // 설문 내용
        qstnIvtExprAmtScleCd: this.dcSuitaCnsntVO.qstnIvtExprAmtScleCd, // 13. 투자경험 금액규모 설문결과
        qstnIvtExprDlFrqnCd: this.dcSuitaCnsntVO.qstnIvtExprDlFrqnCd, // 14. 투자경험 거래빈도 설문결과
        qstnVainsAppreLvCd: this.dcSuitaCnsntVO.qstnVainsAppreLvCd, // 15. 변액보험 이해수준 설문결과
        rskPrpnsQstnRslt: this.dcSuitaCnsntVO.rskPrpnsQstnRslt, // 16. 위험성향 설문결과
        atcptBnfRatQstnRslt: this.dcSuitaCnsntVO.atcptBnfRatQstnRslt, // 17. 기대수익율 설문결과
        losLvQstnRslt: this.dcSuitaCnsntVO.losLvQstnRslt, // 18. 손실감내수준 설문결과
        stckRtoQstnRslt: this.dcSuitaCnsntVO.stckRtoQstnRslt, // 19. 주식형비중 설문결과
        qstnIvtPrdtRtoCd: this.dcSuitaCnsntVO.qstnIvtPrdtRtoCd // 20. 투자상품 보유비중 설문결과
      }
      // 불필요_주석처리 (ASR220700001_서면적합성 진단대상자 호출 로직 보완 요청 / 220719_PJO)
      // this._MB_entPpsQstnRslt = this.dcSuitaCnsntVO.entPpsQstnRslt // 가입목적설문결과
      // this.MB_entDalwObjJdgmt = 'N'
      // window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke('UF1MDI0290', param, this.fn_RequestSuitaDignHandler, null, this.fn_RequestSuitaDignHandler, this, this)
    },
    /********************************************************************************************
     * 함수 : fn_RequestSuitaDignHandler
     * 설명 : S2 응답 처리 Handler
    ********************************************************************************************/
    fn_RequestSuitaDignHandler (pISuitaVO) {
      this.isSuitaExpire = false
      // 위험영향분석결과
      this.rskEftAnlyRslt = pISuitaVO.rskEftAnlyRslt
      // 가입불가대상판단여부와 위험성향분석결과코드로 분석결과 메시지를 설정한다.
      this.entDalwObjJdgmtYn = pISuitaVO.entDalwObjJdgmtYn
      this.rskEftAnlyRslt = pISuitaVO.rskEftAnlyRslt
      if ( pISuitaVO.rskEftAnlyYmd === undefined || String(pISuitaVO.rskEftAnlyYmd).trim() === '') { // 결과없는경우 ' ' 으로 리턴됨 보완(20220118_PJO)
        pISuitaVO.rskEftAnlyYmd = piCommonUtil.getCurrentDate()
      }

      this.fn_setRskEftAnlyRsltMsg( pISuitaVO ) // 위험영향분석결과

      // this.MB_entPpsQstnRslt = pISuitaVO.entPpsQstnRslt // 불필요_주석처리 (ASR220700001_서면적합성 진단대상자 호출 로직 보완 요청/220719_PJO)
      // 미승인건 버튼 비활성화 (20220118_PJO)
      if ( this.aprvYn !== 'Y') {
        this.isDisableBtnCmplt = true // 적합성 선택완료 버튼 비활성화
        this.isDisableBtnPlb = true // 진단결과 발행 버튼 비활성화
      }
      // if (this.entDalwObjJdgmtYn !== 'Y') { // 주석처리 (20220118_PJO)
      //   // btBtnP1.enabled = true; // 미리보기 버튼은 없음
      //   this.isDisableBtnPlb = false // 발행 버튼
      // }

      // 신규 설문조사의 경우, 지점장 승인요청 메시지를 표시한다.(임시고객일 경우 체크 예외)
      let l_contrRrnBkno = this.contrRrnBkno
      console.log('l_contrRrnBkno', l_contrRrnBkno)
      if ( (this.strMB_prextSuitaYn === 'N' || (this.isReSuita && this.aprvYn === 'N')) && this.custInfo.contrRrnBkno.substr(1, 6) !== '000000' ) {
        this.isBmngrMsg = true
        this.alertMsg = this.reqConfirmFromBmngrMsg
        this.$refs.refAlert.open()
        this.subBtn11 = this.subBtn12 = true
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
      }

      window.vue.getStore('progress').dispatch('UPDATE', false)

      // 법인대표자 정보 저장 처리
      this.fn_setPlanSuitaResntInfo()
    },
    /********************************************************************************************
    * 함수 : fn_setRskEftAnlyRsltMsg()
    * 설명 : 위험성향진단결과를 설정
    ********************************************************************************************/
    fn_setRskEftAnlyRsltMsg (pISuitaVO) {
      // 위험영향분석결과에 따른 메세지를 설정한다.
      let rskEftAnlyRslt = pISuitaVO.rskEftAnlyRslt

      // 투자(특별계정펀드) 성향
      // 위험영향분석결과(11:위험회피형,12:안정형,13:중립형 ,14:위험선호형, 15:공격형, 99:진단거부)
      if (rskEftAnlyRslt === '11') {
        this.lbRskEftAnlyRslt = '위험회피형'
      } else if (rskEftAnlyRslt === '12') {
        this.lbRskEftAnlyRslt = '안정형'
      } else if (rskEftAnlyRslt === '13') {
        this.lbRskEftAnlyRslt = '중립형'
      } else if (rskEftAnlyRslt === '14') {
        this.lbRskEftAnlyRslt = '위험선호형'
      } else if (rskEftAnlyRslt === '15') {
        this.lbRskEftAnlyRslt = '공격형'
      }

      // 보험상품 성향
      // 보험상품적합성분석결과에 따른 메세지를 설정한다.
      let qstnPrdtRsltCd = pISuitaVO.qstnPrdtRsltCd // 보험상품적합성(1:부적합,2:저축형,3:연금형,4:보장형)
      if (qstnPrdtRsltCd === '1') {
        this.lbInsrAnlyRslt = '부적합'
      } else if (qstnPrdtRsltCd === '2') {
        this.lbInsrAnlyRslt = '저축형'
      } else if (qstnPrdtRsltCd === '3') {
        this.lbInsrAnlyRslt = '연금형'
      } else if (qstnPrdtRsltCd === '4') {
        this.lbInsrAnlyRslt = '보장형'
      }

      let todayDateAfter3M = DateUtil.addDate(pISuitaVO.rskEftAnlyYmd, +90) // 91일이후날짜(91일이 맞지만, +1일이되서 계산됨/만료일자계산은 90일로 세팅함) 2021.11_PJO

      if ( todayDateAfter3M === '' ) {
        todayDateAfter3M = DateUtil.addDate(pISuitaVO.rskEftAnlyYmd, +90) // 2021.11_PJO
      }

      // 보험상품적합성 결과 메세지 - 날짜
      this.lbInsrAnlyRslt_Date = '( ~ ' + DateUtil.fn_DateFormat(todayDateAfter3M, 'yyyy-MM-dd') + ')'
      // 특별계정펀드 적합성 - 만료 날짜 표시
      this.lbRskEftAnlyRslt_Date = '( ~ ' + DateUtil.fn_DateFormat(todayDateAfter3M, 'yyyy-MM-dd') + ')'
      // 전문금융소비자 정보 배열에 저장
      this.fn_setExportArray()
      // 승인/미승인 라벨 출력
      this.fn_setAprvLabel(pISuitaVO)
    },
    /********************************************************************************************
     * 함수 : fn_setAprvLabel
     * 설명 : 적합성 승인/미승인 텍스트 설정
    ********************************************************************************************/
    fn_setAprvLabel ( pISuitaVO ) {
      if ( this.aprvYn === 'Y') {
        this.aprvMsg = '승인'
        this.isDisableIns = false
      } else {
        this.aprvYn = 'N' // '' 인경우 보완 (20220118_PJO)
        this.aprvMsg = '미승인'
        this.isDisableIns = true
      }

      // 진단결과 노출
      this.card0.result = true
      this.card0.detailAreaOpen = true
    },
    /********************************************************************************************
     * 함수 : fn_cbRskEftAnlyResConfirm
     * 설명 : 위험성향진단 예약발행 ( P2 ) - 전처리
    ********************************************************************************************/
    fn_cbRskEftAnlyResConfirm () {
      if ( this.aprvYn === 'Y') {
        this.isRskEftAnlyRes = true // 위험성향진단 예약발행 \n\n 인쇄를 하시겠습니까?
        this.alertMsg = this.RskEftAnlyResMsg
        this.alertBtnLb1 = '취소'
        this.alertBtnLb2 = '확인'
        this.$refs.refConfirm.open()
      } else {
        // "위험성향 진단 질문지 승인후 발행가능합니다.(진단일 + 3개월)"
        this.isRskEftAnlyAlert = true
        this.alertMsg = this.RskEftAnlyAlertMsg
        this.$refs.refAlert.open()
      }
    },
    /********************************************************************************************
     * 함수 : fn_RequestpblSuita
     * 설명 : 위험성향진단 발행 (P2)
    ********************************************************************************************/
    fn_RequestpblSuita () {
      console.log('fn_RequestpblSuita called')
      let currDateTime = piCommonUtil.getCurrentDateTime() // 처리일자

      console.log('currData', currDateTime)

      // 현재 날짜와 시간 값 설정
      let currDate = currDateTime.substr(0, 8)
      let currTime = currDateTime.substr(8, 12)

      var inputData = {
        dgrstReqCd: 'A', // 진단결과요청코드 - 하드코딩
        tlgmAddmYn: ' ', // 전문추가여부 - 보고서전문추가 일때만 Y, 나머지는 공백  - 하드코딩
        srvcInflPathCd: '6', // 서비스유입경로코드  - 하드코딩
        suitaWcnstId: this.suitaDignId, // 적합성동의서ID
        reqHedrCntntVO: {
          cnsltNo: this.headerVO.userId, // 컨설턴트번호
          busnTrtPathCd: '2222', // 업무처리경로코드  - 하드코딩
          trtSystmPathCd: '22', // 처리시스템경로코드  - 하드코딩
          trtSystmPathCdVal: '사랑온 적합성 발행', // 처리시스템경로코드값  - 하드코딩
          trtTmd: currDate, // 날짜
          trtTm: currTime // 시간
        }
      }
      window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke('UF1MDI0323', inputData, this.fn_ResponsepblSuitaHandler, null, this.fn_ResponsepblSuitaHandler, this, this)
    },
    /************************************************************************************
     * 함수 : fn_ResponsepblSuitaHandler
     * 설명 : P2 요청 처리 Handler
     ************************************************************************************/
    fn_ResponsepblSuitaHandler (result) {
      // 위험성향 발행 결과 설정
      let pISuitaVO = result

      // ChkErrorMsg 를 이용하여 결과 값 알림 팝업 표시 함 - 재활용 하자긔
      this.closeButtonEnabled = true

      if ( pISuitaVO.trnsRsltCd === 'S' ) {
        // this.ChkErrorMsg = '지점 서버로 전송 되었습니다.'
        this.alertMsg = '지점 서버로 전송 되었습니다.'
      } else {
        // this.ChkErrorMsg = pISuitaVO.trnsRsltCntnt
        this.alertMsg = pISuitaVO.trnsRsltCntnt
      }
      this.$refs.refAlert.open()

      // this.isChkErrorMsg = true
      this.STR_suitaReportPblYN = 'Y'
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /********************************************************************************************
     * 함수 : fn_AccordionFocusControl
     * 설명 : 포커스 컨트롤
    ********************************************************************************************/
    fn_AccordionFocusControl(idx) {
      if(idx === '00') {
        if(this.card00.selected) {
          this.card000.detailAreaOpen = true
        }
      }else if(idx === '000') {
        if(this.card000.selected) {
          this.card1.detailAreaOpen = true
        }
      }else {
        let _idx = Number(idx)
        if(_idx === 20) return
        eval('this.card'+_idx+'.selected === true ? this.card'+(_idx+1)+'.detailAreaOpen = true : ""')
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ01
     * 설명 : 전문금융소비자여부 체크 처리 함수
    ********************************************************************************************/
    fn_SetDataQ01 (idx) {
      this.formInput.choice01 = []
      this.formInput.choice01.push(idx.toString())
      if (idx === 2) {
        this.isDisableBtnExprt = false // 전문금융소비자
        this.card00.selectedValue = '전문금융소비자 / 확인완료'
        if(this.SuitaExportArray.length > 0) {
          this.card00.selected = true
          this.card00.detailAreaOpen = !this.card00.detailAreaOpen
        }else {
          this.card00.selected = false
        }
      } else {
        this.SuitaExportArray = []
        this.SuitaExportVO.exprtFncCspTypCd = '' // 전문가금융소비자유형코드
        this.SuitaExportVO.genFncCspSameTrtmtYn = ''// 일반금융소비자동일대우여부
        this.isDisableBtnExprt = true // 전문금융소비자
        this.card00.selectedValue = '일반금융소비자'
        this.card00.selected = true
        this.card00.detailAreaOpen = !this.card00.detailAreaOpen
      }
      this.card00Selected = this.card00.selected
      // focus control
      setTimeout(()=>{this.fn_AccordionFocusControl('00')},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ02
     * 설명 : 금융 취약 계층 답변 처리 화면 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ02 (idx) {
      if ( this.formInput.choice02.length > 2 ) {
        if ( idx === 1) {
          this.formInput.choice02 = []
          this.formInput.choice02.push(idx.toString())
        } else {
          this.formInput.choice02 = []
          this.formInput.choice02.push(idx.toString())
          this.formInput.choice02.push('4')
        }
      } else if ( this.formInput.choice02.length === 0 ) {
        this.formInput.choice02 = []
        this.formInput.choice02.push(idx.toString())
      } else {
        if ( idx === 1 ) {
          if ( this.formInput.choice02.length !== 1 ) {
            this.formInput.choice02 = []
            this.formInput.choice02.push(idx.toString())
          }
        } else {
          if ( this.formInput.choice02[0] === '1') {
            this.formInput.choice02 = []
            this.formInput.choice02.push(idx.toString())
          } else if ( this.formInput.choice02[0] === '2' || this.formInput.choice02[0] === '3' ) {
            if (idx !== 4) {
              this.formInput.choice02 = []
              this.formInput.choice02.push(idx.toString())
            }
          }
        }
      }
      this.fncCnsm.fncCnsmRstcObjYn = 'N'
      this.fncCnsm.highAgeRskYn = 'N'
      this.fncCnsm.miorYn = 'N'
      this.fncCnsm.icmpYn = 'N'

      // 법정대리인 항목 출력여부 체크 및 데이터 설정
      this.isDisableLeglAgnt = true
      this.card000.selectedValue1 = this.card000.selectedValue2 = '' // 텍스트 초기화
      for ( let i = 0; i < this.formInput.choice02.length; i++ ) {
        if ( this.formInput.choice02[i] === '3' || this.formInput.choice02[i] === '4' ) this.isDisableLeglAgnt = false
        if ( this.formInput.choice02[i] === '1' ) {
          this.fncCnsm.fncCnsmRstcObjYn = 'Y' // 취약금융소비자해당여부
          this.card000.selectedValue1 = '해당없음'
        } else if ( this.formInput.choice02[i] === '2' ) {
          this.fncCnsm.highAgeRskYn = 'Y' // 고연령위험여부
          this.card000.selectedValue1 = '해당(65세 이상)'
        } else if ( this.formInput.choice02[i] === '3' ) {
          this.fncCnsm.miorYn = 'Y' // 미성년자여부
          this.card000.selectedValue1 = '해당(미성년자)'
        } else if ( this.formInput.choice02[i] === '4' ) {
          this.fncCnsm.icmpYn = 'Y' // 기타 금치산자여부
          this.card000.selectedValue2 = '해당(정신적 장애 등)'
        } else {
          console.log('Out of Value')
        }
      }

      if(this.formInput.choice02.length > 1) {
        // 선택된 값이 두개일때
        this.subBtn000 = (this.formInput.choice03.length > 0) ? false : true // 확인버튼 활성/비활성화
      }else if(this.formInput.choice02.length > 0) {
        // 선택된 값이 한개일때
        if(this.card000.selectedValue1 !== '') {
          this.card000.selectedValue2 = ''
        }
        if(this.card000.selectedValue2 !== '') {
          this.card000.selectedValue1 = ''
        }
        let tmp1 = this.formInput.choice02[0]
        if(tmp1 === '1' || tmp1 === '2') {
          this.subBtn000 = false // 확인버튼 활성화
        }else if(tmp1 === '3' || tmp1 === '4') {
          this.subBtn000 = (this.formInput.choice03.length > 0) ? false : true // 확인버튼 활성/비활성화
        }
      }else {}
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ02Confirm
     * 설명 : 금융취약계층 확인 버튼 컨트롤
    ********************************************************************************************/
    fn_SetDataQ02Confirm() {
      this.card000.selected = true
      this.card000Selected = this.card000.selected
      this.card000.detailAreaOpen = false
      setTimeout(()=>{this.fn_AccordionFocusControl('000')},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ02Control
     * 설명 : 금융취약계층 open ui 컨트롤
    ********************************************************************************************/
    fn_SetDataQ02Control() {
      if(this.card000.detailAreaOpen) {
        if(this.formInput.choice02.length < 1) {
          this.card000.detailAreaOpen = !this.card000.detailAreaOpen
        }else if(this.isDisableIns) {
          this.card000.detailAreaOpen = !this.card000.detailAreaOpen
        }
      }else {
        this.card000.detailAreaOpen = !this.card000.detailAreaOpen
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ03
     * 설명 : 법정대리인여부 체크 처리 함수
    ********************************************************************************************/
    fn_SetDataQ03 (idx) {
      this.formInput.choice03 = []
      this.formInput.choice03.push(idx.toString())
      this.fncCnsm.leglAgntYn = this.formInput.choice03.length > 0 ? this.formInput.choice03[0] : '' // 법정대리인여부
      this.subBtn000 = (this.formInput.choice03.length > 0) ? false : true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataAssort
     * 설명 : 전체 데이터 셋팅 공통 함수
    ********************************************************************************************/
    fn_SetDataAssort(idx, val) {
      eval('this.formInput.choice'+idx+' = this.choice'+idx+'Items['+val+'-1]')
      eval('this.card'+idx+'.selected = true')
      eval('this.card'+idx+'Selected = this.card'+idx+'.selected')
      eval('this.card'+idx+'.selectedValue = this.formInput.choice'+idx+'.label')
      eval('this.card'+idx+'.detailAreaOpen = !this.card'+idx+'.detailAreaOpen')
      setTimeout(()=>{this.fn_AccordionFocusControl(idx)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ10
     * 설명 : 가입경험상품 처리 화면 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ10 (val) {
      this.formInput.choice10 = this.choice10Items[val-1]
      this.card10.selected = true
      this.card10Selected = this.card10.selected
      this.card10.selectedValue = this.formInput.choice10.label
      this.card10.detailAreaOpen = !this.card10.detailAreaOpen

      // 질문 10번에서 '투자경험 없음' 선택시
      // 질문 11~14번 답변 '투자경험 없음' 자동선택 및 비활성화
      // → '변액보험 부적합' 판정
      // (설문상품결과코드 ZA_QSTN_PRDT_RSLT_CD 가 1인 경우)
      if ( this.formInput.choice10.key === '1' ) {
        this.fn_SetDataInexpr() // 투자경험없음으로 셋팅
        setTimeout(()=>{this.fn_AccordionFocusControl(14)},100)
      } else {
        this.fn_SetDataExpr() // 투자경험있음으로 셋팅
        if ( this.formInput.choice11[0] === '6' ) {
          this.formInput.choice11.splice(0, this.formInput.choice11.length)
        }
        setTimeout(()=>{this.fn_AccordionFocusControl(10)},100)
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ11
     * 설명 : 투자경험기간 처리 화면 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ11 (val) {
      if ( this.formInput.choice11.length !== 0 || this.formInput.choice10.key === '1' ) {
        if ( this.formInput.choice10.key === '0' ) {
          this.formInput.choice11.splice(0, this.formInput.choice11.length)
          this.formInput.choice11.push('6')
          this.card11.selectedValue1 = this.card11.selectedValue2 = this.card11.selectedValue3 = this.card11.selectedValue4 = this.card11.selectedValue5 = ''
          this.card11.selectedValue6 = this.choice11Items[5].label
          this.formInput.choice11.length > 0 ? this.subBtn11 = false : this.subBtn11 = true
        } else if ( this.formInput.choice10.key !== '1' ) {
          // this.formInput.choice11.pop()
          this.fn_SetDataInexpr()
        } else {
          this.formInput.choice11.splice(0, this.formInput.choice11.length)
          this.formInput.choice11.push('6')
          this.card11.selectedValue1 = this.card11.selectedValue2 = this.card11.selectedValue3 = this.card11.selectedValue4 = this.card11.selectedValue5 = ''
          this.card11.selectedValue6 = this.choice11Items[5].label
          this.formInput.choice11.length > 0 ? this.subBtn11 = false : this.subBtn11 = true
        }
        setTimeout(()=>{this.fn_AccordionFocusControl(11)},100)
      }
    },
    /********************************************************************************************
     * 함수 : fn_checkQ11
     * 설명 : 투자경험기간 예외 체크 함수
    ********************************************************************************************/
    fn_checkQ11 (val) {
      if ( this.formInput.choice10.key === '1' ) {
        this.formInput.choice11.pop()
        return
      }
      this.card11.selectedValue1 = this.card11.selectedValue2 = this.card11.selectedValue3 = this.card11.selectedValue4 = this.card11.selectedValue5 = this.card11.selectedValue6 = ''
      for ( let i = 0; i < this.formInput.choice11.length; i++ ) {
        eval('this.card11.selectedValue'+this.formInput.choice11[i]+' = this.choice11Items['+(Number(this.formInput.choice11[i])-1)+'].label')
        if ( this.formInput.choice11[i] === '6' ) {
          let tempVal = this.formInput.choice11.pop()
          this.formInput.choice11.splice(0, this.formInput.choice11.length)
          this.formInput.choice11.push(tempVal)
          this.card11.selectedValue6 = ''
        }
      }
      this.formInput.choice11.length > 0 ? this.subBtn11 = false : this.subBtn11 = true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ11Confirm
     * 설명 : 가입경험상품 확인 버튼 컨트롤
    ********************************************************************************************/
    fn_SetDataQ11Confirm() {
      this.card11.selected = true
      this.card11Selected = this.card11.selected
      this.card11.detailAreaOpen = false
      setTimeout(()=>{this.fn_AccordionFocusControl(11)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ11Control
     * 설명 : 가입경험상품 open ui 컨트롤
    ********************************************************************************************/
    fn_SetDataQ11Control() {
      if(this.isDisableInexpr) return // 선택 비활성화일때
      if(this.card11.detailAreaOpen) {
        if(this.formInput.choice11.length < 1) {
          this.card11.detailAreaOpen = !this.card11.detailAreaOpen
        }else if(this.isDisableIns) {
          this.card11.detailAreaOpen = !this.card11.detailAreaOpen
        }
      }else {
        this.card11.detailAreaOpen = !this.card11.detailAreaOpen
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ12
     * 설명 : 투자경험목적 처리 화면 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ12 (val) {
      if ( this.formInput.choice12.length !== 0 || this.formInput.choice10.key === '1' ) {
        if ( this.formInput.choice10.key === '0' ) {
          this.formInput.choice12.splice(0, this.formInput.choice12.length)
          this.formInput.choice12.push('1')
          this.card12.selectedValue2 = this.card12.selectedValue3 = this.card12.selectedValue4 = this.card12.selectedValue5 = ''
          this.card12.selectedValue1 = this.choice12Items[0].label
          this.formInput.choice12.length > 0 ? this.subBtn12 = false : this.subBtn12 = true
        } else if ( this.formInput.choice10.key !== '1' ) {
          // this.formInput.choice11.pop()
          this.fn_SetDataInexpr()
        } else {
          this.formInput.choice12.splice(0, this.formInput.choice12.length)
          this.formInput.choice12.push('1')
          this.card12.selectedValue2 = this.card12.selectedValue3 = this.card12.selectedValue4 = this.card12.selectedValue5 = ''
          this.card12.selectedValue1 = this.choice12Items[0].label
          this.formInput.choice12.length > 0 ? this.subBtn12 = false : this.subBtn12 = true
        }
        setTimeout(()=>{this.fn_AccordionFocusControl(12)},100)
      }
    },
    /********************************************************************************************
     * 함수 : fn_checkQ12
     * 설명 : 투자경험목적 예외 체크 함수
    ********************************************************************************************/
    fn_checkQ12 (val) {
      if ( this.formInput.choice10.key === '1' ) {
        this.formInput.choice12.pop()
        return
      }
      this.card12.selectedValue1 = this.card12.selectedValue2 = this.card12.selectedValue3 = this.card12.selectedValue4 = this.card12.selectedValue5 = ''
      for ( let i = 0; i < this.formInput.choice12.length; i++ ) {
        eval('this.card12.selectedValue'+this.formInput.choice12[i]+' = this.choice12Items['+(Number(this.formInput.choice12[i])-1)+'].label')
        if ( this.formInput.choice12[i] === '1' ) {
          let tempVal = this.formInput.choice12.pop()
          this.formInput.choice12.splice(0, this.formInput.choice12.length)
          this.formInput.choice12.push(tempVal)
          this.card12.selectedValue1 = ''
        }
      }
      this.formInput.choice12.length > 0 ? this.subBtn12 = false : this.subBtn12 = true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ12Confirm
     * 설명 : 투자경험목적 확인 버튼 컨트롤
    ********************************************************************************************/
    fn_SetDataQ12Confirm() {
      this.card12.selected = true
      this.card12Selected = this.card12.selected
      this.card12.detailAreaOpen = false
      setTimeout(()=>{this.fn_AccordionFocusControl(12)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ12Control
     * 설명 : 가입경험상품 open ui 컨트롤
    ********************************************************************************************/
    fn_SetDataQ12Control() {
      if(this.isDisableInexpr) return // 선택 비활성화일때
      if(this.card12.detailAreaOpen) {
        if(this.formInput.choice12.length < 1) {
          this.card12.detailAreaOpen = !this.card12.detailAreaOpen
        }else if(this.isDisableIns) {
          this.card12.detailAreaOpen = !this.card12.detailAreaOpen
        }
      }else {
        this.card12.detailAreaOpen = !this.card12.detailAreaOpen
      }
    },
    /********************************************************************************************
     * 함수 : fn_checkQ13
     * 설명 : 투자경험 금액규모 예외 체크 함수
    ********************************************************************************************/
    fn_SetDataQ13 (val) {
      this.formInput.choice13 = this.choice13Items[val-1]
      this.card13.selected = true
      this.card13Selected = this.card13.selected
      this.card13.selectedValue = this.formInput.choice13.label
      this.card13.detailAreaOpen = !this.card13.detailAreaOpen

      if ( this.formInput.choice13.key === '1' ) {
        this.fn_SetDataInexpr()
      }
      setTimeout(()=>{this.fn_AccordionFocusControl(13)},100)
    },
    /********************************************************************************************
     * 함수 : fn_checkQ14
     * 설명 : 변액보험 이해수준 예외 체크 함수
    ********************************************************************************************/
    fn_SetDataQ14 (val) {
      this.formInput.choice14 = this.choice14Items[val-1]
      this.card14.selected = true
      this.card14Selected = this.card14.selected
      this.card14.selectedValue = this.formInput.choice14.label
      this.card14.detailAreaOpen = !this.card14.detailAreaOpen

      if ( this.formInput.choice14.key === '1' ) {
        this.fn_SetDataInexpr()
      }
      setTimeout(()=>{this.fn_AccordionFocusControl(14)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataInexpr
     * 설명 : 투자경험 없음으로 설정
    ********************************************************************************************/
    fn_SetDataInexpr () {
      this.formInput.choice10 = this.choice10Items[0]
      this.formInput.choice11.splice(0, this.formInput.choice11.length)
      this.formInput.choice11.push('6')
      // this.formInput.choice12 = this.choice12Items[0]
      this.formInput.choice12.splice(0, this.formInput.choice12.length)
      this.formInput.choice12.push('1')
      this.formInput.choice13 = this.choice13Items[0]
      this.formInput.choice14 = this.choice14Items[0]
      this.isDisableInexpr = true
      // 화면 컨트롤 변수 셋팅
      this.card10.selected = this.card11.selected = this.card12.selected = this.card13.selected = this.card14.selected = true
      this.card10Selected = this.card11Selected = this.card12Selected = this.card13Selected = this.card14Selected = true
      this.card10.selectedValue = this.card11.selectedValue6 = this.card12.selectedValue1 = this.card13.selectedValue = this.card14.selectedValue = this.choice10Items[0].label
      this.card11.selectedValue1 = this.card11.selectedValue2 = this.card11.selectedValue3 = this.card11.selectedValue4 = this.card11.selectedValue5 = ''
      this.card12.selectedValue2 = this.card12.selectedValue3 = this.card12.selectedValue4 = this.card12.selectedValue5 = ''
      this.card10.detailAreaOpen = this.card11.detailAreaOpen = this.card12.detailAreaOpen = this.card13.detailAreaOpen = this.card14.detailAreaOpen = false
      this.c10 = this.c13 = this.c14 = '1'
      this.subBtn11 = this.subBtn12 = true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataInexpr
     * 설명 : 투자경험 있음으로 초기화
    ********************************************************************************************/
    fn_SetDataExpr () {
      this.formInput.choice10 = this.formInput.choice10.key === '1' ? { key: '0', label: '선택하세요' } : this.formInput.choice10
      if (this.formInput.choice11[0] === '6') {
        this.formInput.choice11.splice(0, this.formInput.choice11.length)
        this.card11.selected = this.card11.detailAreaOpen = false
        this.card11Selected = this.card11.selected
        this.card11.selectedValue1 = this.card11.selectedValue2 = this.card11.selectedValue3 = this.card11.selectedValue4 = this.card11.selectedValue5 = this.card11.selectedValue6 = ''
      }
      if (this.formInput.choice12[0] === '1') {
        this.formInput.choice12.splice(0, this.formInput.choice12.length)
        this.card12.selected = this.card12.detailAreaOpen = false
        this.card12Selected = this.card12.selected
        this.card12.selectedValue1 = this.card12.selectedValue2 = this.card12.selectedValue3 = this.card12.selectedValue4 = this.card12.selectedValue5 = ''
      }
      this.formInput.choice13 = this.formInput.choice13.key === '1' ? { key: '0', label: '선택하세요' } : this.formInput.choice13
      this.formInput.choice14 = this.formInput.choice14.key === '1' ? { key: '0', label: '선택하세요' } : this.formInput.choice14
      this.card13.selected = this.card14.selected = this.card13.detailAreaOpen = this.card14.detailAreaOpen = false
      this.card13Selected = this.card14Selected = false
      this.c13 = this.c14 = ''
      this.isDisableInexpr = false
    },
    /********************************************************************************************
     * 함수 : fn_ClosePopup
     * 설명 :알림 팝업 닫을 때 호출하여 처리하는 함수
    ********************************************************************************************/
    // 그냥 알림 팝업 닫을 때 호출 하면 됨
    fn_ClosePopup () {
      if(this.fn_CheckItems()) {
        let alertMsg = '작성중인 화면에서 나가시겠습니까? 나가기 선택 시 작성된 내용은 저장되지 않습니다.'
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              this.$options.isBackKeyHandle = false
              this.$emit('onClose')
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      }else {
        this.$options.isBackKeyHandle = false
        this.$emit('onClose')
      }
    },
    /********************************************************************************************
     * 함수 : fn_ConfirmAuthCheck
     * 설명 : '고객 선택 후 진행하시기 바랍니다' 팝업 처리
    ********************************************************************************************/
    fn_ConfirmAuthCheck () {
      this.isChkErrorMsg = false
      this.isCustCheck = false
      this.isRskEftAnlyAlert = false
    },
    /********************************************************************************************
     * 함수 : fn_suitaEditConfirmPositive
     * 설명 : 버튼 활성화 처리
    ********************************************************************************************/
    fn_suitaEditConfirmPositive () {
      if (this.fn_checkInputAnswer()) {
        return false
      }
      this.isSuitaEditConfirm = false // 적합성 선택완료 버튼 활성
      // 발행 버튼 활성화 시킴
      this.isDisableBtnPlb = false
      // EditConfirmMsg: '영업관리자 ‘질문지 승인’ 이후에 상품설명서(제안용) 발행이 가능합니다',
      this.isEditConfirm = true
      this.alertMsg = this.EditConfirmMsg
      this.$refs.refAlert.open()
    },
    /********************************************************************************************
     * 함수 : fn_suitaEditConfirmNegative
     * 설명 : 적합성 진단 이력이 있는 고객입니다. 취소 버튼 선택 시 처리 함수
    ********************************************************************************************/
    fn_suitaEditConfirmNegative () {
      this.isSuitaEditConfirm = false
    },
    /********************************************************************************************
     * 함수 : fn_suitaEditConfirmNegative
     * 설명 : 위험성향진단 예약발행 팝업 - 확인 선택
    ********************************************************************************************/
    fn_rskEftAnlyResPositive () {
      this.isRskEftAnlyRes = false

      // TODO: P2 Request 처리 하는 곳 - getServiceData("P2");
      // 위험성향진단 예약발행 ( P2 ) - 요청
      this.fn_RequestpblSuita()
    },
    /********************************************************************************************
     * 함수 : fn_rskEftAnlyResNegative
     * 설명 : 위험성향진단 예약발행 팝업 - 취소 선택
    ********************************************************************************************/
    fn_rskEftAnlyResNegative () {
      this.isRskEftAnlyRes = false
    },
    /********************************************************************************************
     * 함수 : fn_EditConfirm
     * 설명 : 재 승인 처리를 위한 적합성 진단 다시 수행
    ********************************************************************************************/
    fn_EditConfirm () {
      this.aprvYn = 'N' // 재 승인 처리로 인해 승인여부 'N'으로 설정
      this.isEditConfirm = false

      window.vue.getStore('progress').dispatch('UPDATE', true)
      // 입력한 응답에 대한 데이터 처리 ( 요청도 해야되고 할게 많음 )
      this.fn_setSuitaAnswerData()
      // S2 요청 처리
      this.fn_RequestSuitaDign()

      // 스크롤 최상단
      this.$refs.page.scrollTo(0)
      // 카드 아코디언 close
      this.card0.detailAreaOpen = true
      this.card00.detailAreaOpen = this.card000.detailAreaOpen = false
      this.card1.detailAreaOpen = this.card2.detailAreaOpen = this.card3.detailAreaOpen = this.card4.detailAreaOpen = this.card5.detailAreaOpen = false
      this.card6.detailAreaOpen = this.card7.detailAreaOpen = this.card8.detailAreaOpen = this.card9.detailAreaOpen = this.card10.detailAreaOpen = false
      this.card11.detailAreaOpen = this.card12.detailAreaOpen = this.card13.detailAreaOpen = this.card14.detailAreaOpen = this.card15.detailAreaOpen = false
      this.card16.detailAreaOpen = this.card17.detailAreaOpen = this.card18.detailAreaOpen = this.card19.detailAreaOpen = this.card20.detailAreaOpen = false
      // 하단 버튼 컨트롤 - 진단결과 발행 / 디세이블
      this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼 show
      this.cmpltBtnTxt = '진단결과 발행'
      this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
      this.isDisableBtnCmplt = true // 진단결과 발행 버튼 비활성화
      this.isShowBtnOldDataUse = false // 기존정보 사용 버튼
      this.isShowBtnRetry = false // 다시진단하기, 변경 재진단 버튼
    },
    /********************************************************************************************
     * 함수 : fn_EditConfirm
     * 설명 : 고객 정보를 찾을 수 없습니다. 팝업 닫기
    ********************************************************************************************/
    fn_NotFndGstFlg () {
      this.isNotFndGstFlg = false
    },
    /******************************************************************************************
     * 함수 : fn_PrePopupBtnConfirmHandler
     * 설명 : 첫화면 진입 후 적합성 진단 결과 유무 체크하기 위한 함수
     ******************************************************************************************/
    fn_PrePopupBtnConfirmHandler () {
      this.$refs.refDefaultModal.close()  // 팝업 닫기
      this.fn_RequestSuitaQstnInve()
    },
    /******************************************************************************************
     * 함수 : fn_showTSSPI721P
     * 설명 : TSSPI721P 보이기 - 적합성 진단 거부 설정 팝업
     ******************************************************************************************/
    fn_showTSSPI721P () {
      this.isTSSPI721P = true
      // 전문금융소비자 입력용 팝업 오픈
      let lv_Vm = this
      lv_Vm.msppi452p = lv_Vm.$bottomModal.open(MSPPI452P, {
        properties: {
          pParam: lv_Vm.SuitaExportArray
        },
        listeners: {
          ExportConfirmData: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.msppi452p)
            lv_Vm.fn_ConfirmTSSPI721P(pData)
          },
          ExportCancelData: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.msppi452p)
          }
        }
      },
      {
        properties: {noHeader: true}
      })
    },
    /******************************************************************************************
     * 함수 : fn_closeBmngrMsg
     * 설명 : '지점장에게 승인요청 하시기 바랍니다.' 닫기
     ******************************************************************************************/
    fn_closeBmngrMsg () {
      this.isBmngrMsg = false
    },
    /******************************************************************************************
     * 함수 : fn_ConfirmTSSPI721P
     * 설명 :  TSSPI721P 닫고, 전달값 설정 - 적합성 진단 거부 설정값 전달 받아 처리
     ******************************************************************************************/
    fn_ConfirmTSSPI721P (param) {
      if (param === false) {return}

      this.isTSSPI721P = false
      this.isDisableBtnPlb = true
      this.SuitaExportArray = param
      this.SuitaExportVO.exprtFncCspTypCd = '' // 전문가금융소비자유형코드
      this.SuitaExportVO.genFncCspSameTrtmtYn = ''// 일반금융소비자동일대우여부

      for ( let i = 0; i < this.SuitaExportArray.length; i++ ) {
        if ( this.SuitaExportArray[i] === '1' ) {
          this.SuitaExportVO.exprtFncCspTypCd = '1' // 전문가금융소비자유형코드: 국가/지자체/공공기관 및 법인/단체 등
        } else if ( this.SuitaExportArray[i] === '2' ) {
          this.SuitaExportVO.exprtFncCspTypCd = '2' // 전문가금융소비자유형코드: 보험설계사대리점 및 보험중개사 등
        } else if ( this.SuitaExportArray[i] === '9' ) {
          this.SuitaExportVO.exprtFncCspTypCd = '9' // 전문가금융소비자유형코드: 기타
        } else if ( this.SuitaExportArray[i] === '4' ) {
          this.SuitaExportVO.genFncCspSameTrtmtYn = 'Y'// 일반금융소비자동일대우여부
        } else {
          console.log('Error!!! Condition')
        }
      }

      if(this.SuitaExportArray.length > 0) {
        this.card00.selected = true
        this.card00Selected = this.card00.selected
        this.card00.detailAreaOpen = !this.card00.detailAreaOpen
        setTimeout(()=>{this.fn_AccordionFocusControl('00')},100)
      }
    },
    /******************************************************************************************
     * 함수 : fn_clearn
     * 설명 : 화면 선택 답변 정보 초기화 처리
     ******************************************************************************************/
    fn_clearn () {
      this.formInput = {
        choice01: [],
        choice02: [],
        choice03: [],
        choice1: { key: '0', label: '선택하세요' },
        choice2: { key: '0', label: '선택하세요' },
        choice3: { key: '0', label: '선택하세요' },
        choice4: { key: '0', label: '선택하세요' },
        choice5: { key: '0', label: '선택하세요' },
        choice6: { key: '0', label: '선택하세요' },
        choice7: { key: '0', label: '선택하세요' },
        choice8: { key: '0', label: '선택하세요' },
        choice9: { key: '0', label: '선택하세요' },
        choice10: { key: '0', label: '선택하세요' },
        choice11: [],
        choice12: [], // { key: '0', label: '선택하세요' },
        choice13: { key: '0', label: '선택하세요' },
        choice14: { key: '0', label: '선택하세요' },
        choice15: { key: '0', label: '선택하세요' },
        choice16: { key: '0', label: '선택하세요' },
        choice17: { key: '0', label: '선택하세요' },
        choice18: { key: '0', label: '선택하세요' },
        choice19: { key: '0', label: '선택하세요' },
        choice20: { key: '0', label: '선택하세요' }
      }
      this.c0 = this.c00 = this.c000 = ''
      this.c1 = this.c2 = this.c3 = this.c4 = this.c5 = this.c6 = this.c7 = this.c8 = this.c9 = this.c10 = this.c11 = this.c12 = this.c13 = this.c14 = this.c15 = this.c16 = this.c17 = this.c18 = this.c19 = this.c20 = ''
      this.card0 = {selected: false, selectedValue:'', detailAreaOpen: false, result: false}
      this.card00 = {selected: false, selectedValue:'', detailAreaOpen: this.card00.detailAreaOpen ? true : false}
      this.card000 = {selected: false, selectedValue1:'',selectedValue2:'', detailAreaOpen: false}
      this.card1 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card2 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card3 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card4 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card5 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card6 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card7 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card8 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card9 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card10 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card11 = {
        selected: false,
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'', selectedValue6:'',
        detailAreaOpen: false
      }
      this.card12 = {
        selected: false, 
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'',
        detailAreaOpen: false
      }
      this.card13 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card14 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card15 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card16 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card17 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card18 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card19 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card20 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card0Selected = this.card00Selected = this.card000Selected = false
      this.card1Selected = this.card2Selected = this.card3Selected = this.card4Selected = this.card5Selected = false
      this.card6Selected = this.card7Selected = this.card8Selected = this.card9Selected = this.card10Selected = false
      this.card11Selected = this.card12Selected = this.card13Selected = this.card14Selected = this.card15Selected = false
      this.card16Selected = this.card17Selected = this.card18Selected = this.card19Selected = this.card20Selected = false
      this.rDignYn = '' // 재진단여부
      this.rDignRsnCd = '' // 재진단사유코드
    },
    /******************************************************************************************
     * 함수 : fn_ConfirmTSSPI723P
     * 설명 :  TSSPI723P 적합성재진단 팝업 - 팝업창 '확인' 버튼 클릭 시 호출
     ******************************************************************************************/
    fn_ConfirmTSSPI723P (param) {
      this.isTSSPI723P = false
      this.isTSSPI723PConfirm = true
      this.$refs.refDignRsnAlert.close() // 바텀시트 닫기

      this.rDignYn = 'Y'
      if ( this.rDignRsnCd !== '' ) {
        // 초기화
        // this.fn_clearn()
        // this.isDisableIns = false
        // this.isDisableInexpr = false
        // this.isReSuita = false
        // this.card00.detailAreaOpen = true
        this.isShowBtnCmplt = false
        this.isShowBtnOldDataUse = this.isShowBtnRetry = true
        this.isDisableBtnCmplt = false
      }
      // 당일 재진단이 아닐경우 질문지 항목 15~20번도 수정할 수 있게 변경한다.
      if(this.dcSuitaCnsntVO && this.dcSuitaCnsntVO.rskEftAnlyYmd !== '' && this.dcSuitaCnsntVO.rskEftAnlyYmd !== piCommonUtil.getCurrentDate()) {
        this.isReSuita = false
      }
    },
    /******************************************************************************
    * Function명 : fn_CancelTSSPI723P
    * 설명       : 재진단 사유 팝업  - 팝업창(X) 닫는 이벤트 emit
    ******************************************************************************/
    fn_CancelTSSPI723P (param) {
      this.$refs.refDignRsnAlert.close() // 바텀시트 닫기
      this.rDignRsnCd = ''
      if(this.btnStatus1 === '1') {
        // 선택완료
        this.isDisableBtnCmplt = true // 비활성화
      }else if(this.btnStatus1 === '2') {
        // 진단결과 발행
        this.isDisableBtnCmplt = false // 활성화
      }
    },

    _fn_End(){return} // 코딩 종료 함수
  },
  props: {'pParams': null}, // 부모창에서 오는 Param
  data () {
    return {
      lv_HeaderviewScrollCompID: null,
      displayCustNm: '',
      isComplateGENSuita: false,
      isGENFlg: false,
      isGENMsg: '계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.',
      isTSSPI310P: false,
      msppi404p: {}, // MSPPI404P 법인고객조회 팝업
      // showDefaultModal: true,
      showPopup: true,
      inputDone: true,
      // 여기는 GAF 친구들 [
      rltnrInfo: [], // Gaf에서 받은 고객(주피 정보)
      // 여기는 GAF 친구들 ]
      // 사랑온에서 가져온 변수 [
      closeButtonEnabled: true,
      STR_suitaReportPblYN: 'Y',
      aprvYn: '', // 승인여부
      aprvMsg: '', // 승인 여부 메시지
      suitaDignId: '', // 적합성진단ID
      MB_entPpsQstnRslt: '', // 가입목적설문결과
      MB_entDalwObjJdgmt: 'N', // 가입 불가 대상판
      strMB_prextSuitaYn: 'N', // 적합성 수행 이력이 있는 경우 Y, 아니면 N
      lbRskEftAnlyRslt: '', // 위험영향분석결과에 따른 메세지 [ 특별계정펀드 적합성 ]
      lbRskEftAnlyRslt_Date: '', // [ 특별계정펀드 적합성 ] 만료 날짜
      lbInsrAnlyRslt: '', // 보험상품적합성 결과 메세지
      lbInsrAnlyRslt_Date: '', // [ 보험상품적합성 ] 만료 날짜
      entDalwObjJdgmtYn: '', // 가입불가대상판단여부
      rskEftAnlyRslt: '', // 위험성향분석결과코드
      // 사랑온에서 가져온 변수 ]
      headerVO: '', // 사용자 정보 저장 변수
      custInfo: {
        custNm: '', // 가입자 이름
        age: '', // 가입자 나이
        knb: '', // 가입자 주민 번호
        celno: '', // 가입자 전화번호
        emailAddr: '', // 가입자 이메일 주소
        cnsltNo: '', // 사용자 ID
        chnlCustId: '', // 채널 고객 ID
        zzcusDtlTypCd: '', // 고객상세유형코드
        contrChnlCustId: '', // 계약자 채널 고객 ID
        lbContrRrn: '' // 가입자 주민 번호
      },
      fncCnsm: { // 금융 취약 계층 답변
        fncCnsmRstcObjYn: '', // 취약금융소비자해당여부
        highAgeRskYn: '', // 고연령위험여부
        icmpYn: '' // 금치산자여부
      },
      ivtExpr: {
        ivtExprStckMngQstnRslt: '', // 투자경험주식관리설문결과
        ivtExprStckGenQstnRslt: '', // 투자경험주식일반설문결과
        ivtExprBlendQstnRslt: '', // 투자경험혼합설문결과
        ivtExprBtypQstnRslt: '', // 투자경험채권형설문결과
        ivtExprVainsQstnRslt: '', // 투자경험변액설문결과
        ivtExprNhvQstnRslt: '' // 투자경험무설문결과
      },
      qstnExpr: { // 12. 투자경험 목적 설문결과
        qstnIvtNhvExprYn: '', // 12. 위험성향설문결과: 설문투자무경험여부
        qstnExprSprdFrtunYn: '', // 12. 위험성향설문결과: 설문경험단기재산여부
        qstnExprLtmSavYn: '', // 12. 위험성향설문결과: 설문경험장기저축여부
        qstnExprAnutAsetYn: '', // 12. 위험성향설문결과: 설문경험연금자산여부
        qstnExprCovrAsetYn: '' // 12. 위험성향설문결과: 설문경험보장자산여부
      },
      // 적합성 입력 완료 후 적합성 심사 시 서버에 요청할 VO
      dcSuitaCnsntVO: null,
      // 적합성 거부 정보 선택한 VO
      // SuitaDnlArray: [],
      // SuitaDnlVO: {
      //   dignDnlIvtPrdtExprYn: 'N', // 금융투자상품 거래 경험자
      //   dignDnlIsbizEngaYn: 'N', // 전직 및 현직 보험업 종사자
      //   dignDnlFncBizEngaYn: 'N', // 금융회사 근무경력자 (5년이상)
      //   dignDnlFncafAcctExprYn: 'N' // 재무 및 회계분야 근무경력자 (5년이상)
      // },
      SuitaExportArray: [],
      SuitaExportVO: {
        exprtFncCspYn: 'N', // 전문가금융소비자여부
        exprtFncCspTypCd: '', // 전문가금융소비자유형코드
        genFncCspSameTrtmtYn: 'N'// 일반금융소비자동일대우여부
      },
      isDisableIns: true, // 응답 입력 비활성화 처리
      isDisableLeglAgnt: true, // 법정대리인여부 비활성화 처리
      // isDisableBtnDnl: false, // 적합성 진단 거부 버튼 비활성 처리
      isDisableBtnExprt: true, // 전문금융소비자
      isDisableBtnCmplt: true, // 적합성 입력 완료 버튼 비활성 처리 // true -> false (22.01.18_PJO)
      isDisableBtnPlb: true, // 발행 버튼 비활성 처리
      //----------------------------------
      isShowBtnCmplt: true,
      cmpltBtnTxt: '선택완료',
      btnStatus1: '1', // 1-선택완료, 2-적합성 발행
      isShowBtnOldDataUse: false,
      isShowBtnRetry: false,
      //----------------------------------
      isDisableInexpr: false, // 투자경험 없음
      isTSSPI721P: false, // 적합성 진단 거부 팝업
      isTSSPI723P: false, // 적합성 재진단 팝업
      isReSuita: false, // 적합성 재진단 유무
      rDignYn: '', // 재진단여부
      rDignRsnCd: '', // 재진단사유코드
      isUnAprv: false, // 진단 미승인 메시지 알림
      STR_suitaReportYN: 'N', // 적합성 보고서 발행 대상. 초기값로는 가설 메인에서 값을 전달 해줌
      isCustCheck: false, // 고객 정보 유무 체크 후 팝업 표시
      isSuitaEditConfirm: false, // 승인 여부 체크 - 현재 적합성이 적합성 심사 받은 경우 true
      ErrorMsg1: '고객 선택 후 진행하시기 바랍니다',
      ErrorMsg2: '적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?',
      ErrorMsg3: '승인되지 않은 질문지가 있습니다.\n질문지 승인 후 재입력 가능합니다.',
      isRskEftAnlyRes: false,
      RskEftAnlyResMsg: '위험성향진단 예약발행 \n\n 인쇄를 하시겠습니까?',
      isRskEftAnlyAlert: false,
      RskEftAnlyAlertMsg: '위험성향 진단 질문지 승인후 발행가능합니다.(진단일 + 3개월)',
      isChkErrorMsg: false,
      ChkErrorMsg: '',
      isEditConfirm: false,
      isPrpnsDignDnl: false, // 적합성 이력이 있는 경우, 적합성 진단 거부 시 알림 팝업 표시 flag
      isNotFndGstFlg: false,
      isNotFndGstMsg: '고객 정보를 찾을 수 없습니다.',
      isBmngrMsg: false,
      EditConfirmMsg: '영업관리자 ‘질문지 승인’ 이후에 상품설명서(제안용) 발행이 가능합니다.',
      isSuitaExpire: false, // 적합성 심사 결과 3개월 초과 여부 확인 변수 - 팝업창 띄우기용
      ChkExpirMsg: '적합성진단 유효기간(91일)이 만료되어 사용하실 수 없습니다. 적합성진단을 재수행 바랍니다.', // 3개월->91일 (2021.11_PJO)
      prpnsDignDnlAlertMsg: '부적절한 방법으로 위험성향진단 거부를\n유도했을 경우 보험업법 및 자본시장법 위반입니다.\n(지점장에게 승인요청 하시기 바랍니다)', // 적합성 이력이 있는 경우, 적합성 진단 거부 시 알림 팝업
      reqConfirmFromBmngrMsg: '지점장에게 승인요청 하시기 바랍니다.',
      alertPopupSuitaMsg: '재진단사유가 입력되지 않았습니다.\n\n재진단 사유 입력 하시겠습니까?', // (20220118_PJO)
      alertPopupSuitaFlag: false,
      isTSSPI723PConfirm: false, // 적합성 재진단 팝업 '확인' 버튼 클릭시 true변경됨
      ErrorMsgs: {
        label01: '전문금융소비자여부가 선택되지 않았습니다.',
        label012: '하단에 있는 전문금융소비자 버튼을 클릭하여 전문금융소비자 정보를 입력해주세요.',
        label02: '금융취약계층의 항목중 하나이상 선택해주십시오.',
        label03: '법정대리인여부가 선택되지 않았습니다.',
        label1: '1.가입희망상품이 선택되지 않았습니다.',
        label2: '2.변액보험 가입목적이 선택되지 않았습니다.',
        label3: '3.월평균 소득이 선택되지 않았습니다.',
        label4: '4.월평균 소득比 보험료 비중이 선택되지 않았습니다.',
        label5: '5.보험료 재원 순자산이 선택되지 않았습니다.',
        label6: '6.추가납부 가능금액이 선택되지 않았습니다.',
        label7: '7.보험료 납입가능기간이 선택되지 않았습니다.',
        label8: '8.예상 유지기간이 선택되지 않았습니다.',
        label9: '9.중도해지 가능성이 선택되지 않았습니다.',
        label10: '10.투자경험기간이 선택되지 않았습니다.',
        label11: '11.가입경험상품의 항목중 하나이상 선택해주십시오.',
        label12: '12.투자경험 목적의 항목중 하나이상 선택해주십시오.',
        label13: '13.투자경험 금액규모가 선택되지 않았습니다.',
        label14: '14.투자경험 거래빈도가 선택되지 않았습니다.',
        label15: '15.변액보험 등 금융상품 이해수준이 선택되지 않았습니다.',
        label16: '16.위험성향이 선택되지 않았습니다.',
        label17: '17.기대수익률이 선택되지 않았습니다.',
        label18: '18.손실감내수준이 선택되지 않았습니다.',
        label19: '19.주식형비중이 선택되지 않았습니다.',
        label20: '20.투자상품 보유비중이 선택되지 않았습니다.'
      },
      // 화면에서 사용, 화면 처리 변수
      formInput: {
        choice01: [],
        choice02: [],
        choice03: [],
        choice1: { key: '0', label: '선택하세요' },
        choice2: { key: '0', label: '선택하세요' },
        choice3: { key: '0', label: '선택하세요' },
        choice4: { key: '0', label: '선택하세요' },
        choice5: { key: '0', label: '선택하세요' },
        choice6: { key: '0', label: '선택하세요' },
        choice7: { key: '0', label: '선택하세요' },
        choice8: { key: '0', label: '선택하세요' },
        choice9: { key: '0', label: '선택하세요' },
        choice10: { key: '0', label: '선택하세요' },
        choice11: [],
        choice12: [], // { key: '0', label: '선택하세요' },
        choice13: { key: '0', label: '선택하세요' },
        choice14: { key: '0', label: '선택하세요' },
        choice15: { key: '0', label: '선택하세요' },
        choice16: { key: '0', label: '선택하세요' },
        choice17: { key: '0', label: '선택하세요' },
        choice18: { key: '0', label: '선택하세요' },
        choice19: { key: '0', label: '선택하세요' },
        choice20: { key: '0', label: '선택하세요' }
      },
      //-----------------------------------------------------------------------------------------------------------------
      c0: '',c00: '',c000: '',
      c1: '',c2: '',c3: '',c4: '',c5: '',c6: '',c7: '',c8: '',c9: '',c10: '',c11: '',c12: '',c13: '',c14: '',c15: '',c16: '',c17: '',c18: '',c19: '',c20: '',
      card0: {selected: false, selectedValue:'', detailAreaOpen: false, result: false},
      card00: {selected: false, selectedValue:'', detailAreaOpen: false},
      card000: {selected: false, selectedValue1:'',selectedValue2:'', detailAreaOpen: false},
      card1: {selected: false, selectedValue:'', detailAreaOpen: false},
      card2: {selected: false, selectedValue:'', detailAreaOpen: false},
      card3: {selected: false, selectedValue:'', detailAreaOpen: false},
      card4: {selected: false, selectedValue:'', detailAreaOpen: false},
      card5: {selected: false, selectedValue:'', detailAreaOpen: false},
      card6: {selected: false, selectedValue:'', detailAreaOpen: false},
      card7: {selected: false, selectedValue:'', detailAreaOpen: false},
      card8: {selected: false, selectedValue:'', detailAreaOpen: false},
      card9: {selected: false, selectedValue:'', detailAreaOpen: false},
      card10: {selected: false, selectedValue:'', detailAreaOpen: false},
      card11: {
        selected: false,
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'', selectedValue6:'',
        detailAreaOpen: false
      },
      card12: {
        selected: false, 
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'',
        detailAreaOpen: false
      },
      card13: {selected: false, selectedValue:'', detailAreaOpen: false},
      card14: {selected: false, selectedValue:'', detailAreaOpen: false},
      card15: {selected: false, selectedValue:'', detailAreaOpen: false},
      card16: {selected: false, selectedValue:'', detailAreaOpen: false},
      card17: {selected: false, selectedValue:'', detailAreaOpen: false},
      card18: {selected: false, selectedValue:'', detailAreaOpen: false},
      card19: {selected: false, selectedValue:'', detailAreaOpen: false},
      card20: {selected: false, selectedValue:'', detailAreaOpen: false},
      card0Selected: false, card00Selected: false, card000Selected: false,
      card1Selected: false, card2Selected: false, card3Selected: false, card4Selected: false, card5Selected: false,
      card6Selected: false, card7Selected: false, card8Selected: false, card9Selected: false, card10Selected: false,
      card11Selected: false, card12Selected: false, card13Selected: false, card14Selected: false, card15Selected: false,
      card16Selected: false, card17Selected: false, card18Selected: false, card19Selected: false, card20Selected: false,
      subBtn000: true, subBtn11: true, subBtn12: true, // 확인 버튼 disabled
      mspcm129P: {}, // MSPCM129P 고객조회 팝업
      msppi404p: {}, // MSPPI404P 고객조회 팝업
      msppi452p: {}, // MSPPI452P 전문금융소비자 입력용 팝업
      isDisableCust: true, // 고객명 입력필드 활성/비활성 flag
      alertMsg: '', // alert 메시지
      alertBtnLb: '확인',
      alertBtnLb1: '',
      alertBtnLb2: '',
      searchable: true, // 고객검색버튼 show/hide
      oldDataUseFlag: false,
      //-----------------------------------------------------------------------------------------------------------------
      // 1.가입목적 금융상품
      choice1Items: [
        {key: '1', label: '예금·적금'},
        {key: '2', label: '주식·채권 등 금융투자상품'},
        {key: '3', label: '일반보험'},
        {key: '4', label: '변액보험'}
      ],
      // 2.변액보험 가입목적
      choice2Items: [
        {key: '1', label: '단기 재산 증식'},
        {key: '2', label: '장기 저축을 통한 목돈 마련'},
        {key: '3', label: '노후를 위한 연금자산 마련'},
        {key: '4', label: '위험에 대비한 가족의 보장자산 마련'}
      ],
      // 3.월푱균 소득
      choice3Items: [
        {key: '1', label: '200만원 이하'},
        {key: '2', label: '500만원 이하'},
        {key: '3', label: '1,000만원 이하'},
        {key: '4', label: '1,000만원 초과'}
      ],
      // 4.월평균 소득比 보험료 비중
      choice4Items: [
        {key: '1', label: '10% 미만'},
        {key: '2', label: '10 ~ 20%'},
        {key: '3', label: '20 ~ 30%'},
        {key: '4', label: '30 ~ 50%'},
        {key: '5', label: '50% 이상'}
      ],
      // 5.보험료 재원 순자산
      choice5Items: [
        {key: '1', label: '1천만원 이하'},
        {key: '2', label: '5천만원 이하'},
        {key: '3', label: '1억원 이하'},
        {key: '4', label: '1억원 초과'}
      ],
      // 6.추가납부 가능금액
      choice6Items: [
        {key: '1', label: '10만원 이하'},
        {key: '2', label: '10 ~ 30만원'},
        {key: '3', label: '30 ~ 50만원'},
        {key: '4', label: '50 ~ 100만원'},
        {key: '5', label: '100만원 초과'}
      ],
      // 7.보험료 납입가능기간
      choice7Items: [
        {key: '1', label: '3년 미만'},
        {key: '2', label: '7년 미만'},
        {key: '3', label: '10년 미만'},
        {key: '4', label: '20년 미만'},
        {key: '5', label: '20년 이상'}
      ],
      // 8.예상 유지기간
      choice8Items: [
        {key: '1', label: '7년 미만'},
        {key: '2', label: '7 ~ 10년'},
        {key: '3', label: '10 ~ 20년'},
        {key: '4', label: '20 ~ 30년'},
        {key: '5', label: '30년 초과'}
      ],
      // 9.중도해지 가능성
      choice9Items: [
        {key: '1', label: '30% 미만'},
        {key: '2', label: '30 ~ 50%'},
        {key: '3', label: '50 ~ 70%'},
        {key: '4', label: '70 ~ 90%'},
        {key: '5', label: '90% 이상'}
      ],
      // 10.투자경험기간
      choice10Items: [
        {key: '1', label: '투자경험 없음'},
        {key: '2', label: '6개월 미만'},
        {key: '3', label: '1년 미만'},
        {key: '4', label: '2년 미만'},
        {key: '5', label: '2년 이상'}
      ],
      // 11.가입경험상품(최근2년이내)
      choice11Items: [
        {key: '1', label: '주식(관리, 위험종목) 등'},
        {key: '2', label: '주식(일반종목), 주식형 펀트(시장수익률) 등'},
        {key: '3', label: '혼합형 펀드 등'},
        {key: '4', label: '채권형 펀드 등'},
        {key: '5', label: '변액보험'},
        {key: '6', label: '투자경험 없음'}
      ],
      // 12.투자경험 목적
      choice12Items: [
        {key: '1', label: '투자경험 없음'},
        {key: '2', label: '단기 재산증식'},
        {key: '3', label: '장기 저축을 통한 목돈 마련'},
        {key: '4', label: '노후를 위한 연금자산 마련'},
        {key: '5', label: '위험에 대비한 가족의 보장자산 마련'}
      ],
      // 13.투자경험 금액규모 (최근 2년 이내)
      choice13Items: [
        {key: '1', label: '투자경험 없음'},
        {key: '2', label: '200만원 미만'},
        {key: '3', label: '500만원 미만'},
        {key: '4', label: '1,000만원 미만'},
        {key: '5', label: '1,000만원 이상'}
      ],
      // 14.투자경험 거래빈도 (최근 2년 이내)
      choice14Items: [
        {key: '1', label: '투자경험 없음'},
        {key: '2', label: '3회 미만'},
        {key: '3', label: '3 ~ 5회'},
        {key: '4', label: '5 ~ 10회'},
        {key: '5', label: '10회 이상'}
      ],
      // 15.변액보험 등 금융상품 이해수준
      choice15Items: [
        {key: '1', label: '이해수준이 현저히 낮음'},
        {key: '2', label: '가입경험이 있는 상품의 구조와 위험에 대해 일정 부분 이해'},
        {key: '3', label: '가입경험이 있는 상품의 구조와 위험에 대해 전체적으로 잘 이해'}
      ],
      // 16.위험성향
      choice16Items: [
        {key: '1', label: '원금은 반드시 보존되어야 한다'},
        {key: '2', label: '원금 보존을 중요하게 생각한다'},
        {key: '3', label: '수익률이 어느 정도 되어야 한다'},
        {key: '4', label: '수익률을 중요시한다'}
      ],
      // 17.기대수익률
      choice17Items: [
        {key: '1', label: '3% 이내'},
        {key: '2', label: '7% 이내'},
        {key: '3', label: '10% 이내'},
        {key: '4', label: '15% 이내'},
        {key: '5', label: '15% 초과'}
      ],
      // 18.손실감내수준
      choice18Items: [
        {key: '1', label: '어떠한 경우에도 손실이 나면 안된다'},
        {key: '2', label: '원금 기준 10%이내는 감수할 수 있다.'},
        {key: '3', label: '원금 기준 20%이내는 감수할 수 있다.'},
        {key: '4', label: '원금 기준 30%이내는 감수할 수 있다.'},
        {key: '5', label: '원금 기준 40%이내는 감수할 수 있다.'},
        {key: '6', label: '원금 기준 50%이내는 감수할 수 있다.'},
        {key: '7', label: '원금 기준 100% 수준도 감수할 수 있다.'}
      ],
      // 19.주식형비중
      choice19Items: [
        {key: '1', label: '5% 미만'},
        {key: '2', label: '10% 미만'},
        {key: '3', label: '20% 미만'},
        {key: '4', label: '50% 미만'},
        {key: '5', label: '50% 이상'}
      ],
      // 20.투자상품 보유비중
      choice20Items: [
        {key: '1', label: '없음'},
        {key: '2', label: '30% 미만'},
        {key: '3', label: '50% 미만'},
        {key: '4', label: '50% 이상'}
      ]
    }
  }
}
</script>